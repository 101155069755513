import React, { useState, useContext, Fragment, useEffect } from 'react';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
//import { RestApiRepository } from '../../application/restapirepository';
import { PeriodicalList } from './list';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, Button, Tooltip, Box, Typography, TextField, CircularProgress } from '@material-ui/core';
//import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { ISubscription } from '../../application/models';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReceiveIssueDialog } from './receive';
import { EditSubscriptionDialog } from './edit';
import { DeactivatePeriodicalDialog } from './deactivate';


export interface IPeriodicalsProps {
}

interface IPeriodicalsState {
    filterText: string;
    isLoading: boolean;
    periodicals: Array<ISubscription>;
}

interface IDialogState {
    activeSubscription: ISubscription | null;
    openReceive: boolean;
    openEdit: boolean;
    openDeactivate: boolean;
}

const GetDefaultDialogState: () => IDialogState = () => {
    return {
        activeSubscription: null,
        openReceive: false,
        openDeactivate: false,
        openEdit: false
    };
}

export const Periodicals: React.FC<IPeriodicalsProps> = (props, context) => {
    const [state, setState] = useState<IPeriodicalsState>({ filterText: "", isLoading: true, periodicals: [] });
    const [dialogState, setDialogState] = useState<IDialogState>(GetDefaultDialogState());
    const {ReceiveIssue, SaveSubscription, DeleteSubscription, DeleteIssue, FetchPeriodicals } = useFieldsteadApi();
    //const api: RestApiRepository = useContext(ApiContext);

    const closeDialogs: () => void = () => {
        setDialogState({ ...dialogState, activeSubscription: null, openReceive: false, openDeactivate: false, openEdit: false });
    }

    const completeDialog: () => void = () => {
        setState({ ...state, isLoading: true });
        closeDialogs();
    }

    const openEdit: (data: ISubscription) => void = (data) => {
        setDialogState({ ...dialogState, activeSubscription: data, openReceive: false, openDeactivate: false, openEdit: true });
    }

    const openReceive: (data: ISubscription) => void = (data) => {
        setDialogState({ ...dialogState, activeSubscription: data, openReceive: true, openDeactivate: false, openEdit: false });
    }

    const openDeactivate: (data: ISubscription) => void = (data) => {
        setDialogState({ ...dialogState, activeSubscription: data, openReceive: false, openDeactivate: true, openEdit: false });
    }

    const AddSubscription: () => void = () => {
        setDialogState({ ...dialogState, activeSubscription: null, openReceive: false, openDeactivate: false, openEdit: true });
    }

    const saveIssue: (id: number, date: string) => void = (id, date) => {
        ReceiveIssue(id, date).then((reponse) => { completeDialog(); });
    }

    const saveSubscription: (data: ISubscription) => void = (data) => {
        SaveSubscription(data).then((response) => { completeDialog(); });
    }

    const deactivateSubscription: (data: ISubscription) => void = (data) => {
        DeleteSubscription(data).then((response) => { completeDialog(); });
    }

    const clearIssue: (issueDate: string) => void = (issueDate) => {
        if (dialogState.activeSubscription != null) {
            DeleteIssue(dialogState.activeSubscription, issueDate).then((response) => { completeDialog(); });
        }
    }

    const searchChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
        setState({ ...state, filterText: e.target.value });
    }

    const clearSearch: () => void = () => {
        setState({ ...state, filterText: "" });
    }

    const renderPeriodicals: (items: Array<ISubscription>) => void = (items) => {
        setState({ ...state, isLoading: false, periodicals: items, filterText: "" });
    }

    useEffect(() => {
        if (state.isLoading) {
            FetchPeriodicals().then((response) => { renderPeriodicals(response); });
        }
    }, [state.isLoading]);

    return (
        <Grid container spacing={1}>
            <ReceiveIssueDialog open={dialogState.openReceive} save={saveIssue} cancel={closeDialogs} subscription={dialogState.activeSubscription} />
            <EditSubscriptionDialog open={dialogState.openEdit} save={saveSubscription} cancel={closeDialogs} subscription={dialogState.activeSubscription} />
            <DeactivatePeriodicalDialog open={dialogState.openDeactivate} deactivate={deactivateSubscription} cancel={closeDialogs} clearIssue={clearIssue} subscription={dialogState.activeSubscription} />
            <Grid item xs={10} container spacing={1} alignItems='center'>
                <Grid item xs={12} sm={2}><Typography>Filter</Typography></Grid>
                <Grid item xs={11} sm={9}>
                    <TextField value={state.filterText} fullWidth variant='outlined' onChange={searchChange} size='small' disabled={state.isLoading} autoFocus />
                </Grid>
                <Grid item xs={1}>
                    <Button onClick={clearSearch} variant="contained" color="primary" size='large'><FontAwesomeIcon icon={faTimes} /></Button>
                </Grid>
                {state.isLoading ?
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid> :
                    <Grid item xs={12}>
                        <PeriodicalList items={state.periodicals} isLoading={state.isLoading}
                            refresh={() => { setState({ ...state, isLoading: true }); }}
                            closeDialogs={closeDialogs}
                            openEdit={openEdit}
                            openReceive={openReceive}
                            openDeactivate={openDeactivate}
                            filter={state.filterText} />
                    </Grid>}
            </Grid>
            <Grid container item xs={2} justifyContent='center' alignContent='flex-start'>
                <Button color='primary' variant='contained' onClick={AddSubscription} startIcon={<FontAwesomeIcon size='xs' icon={faPlus} />}  ><Typography><Box fontSize='.7em'>Add Periodical</Box></Typography></Button>
            </Grid>
        </Grid>
    );
}