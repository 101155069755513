import React, { useEffect, useContext, useState, Fragment } from 'react';
//import { RestApiRepository } from '../application/restapirepository';
//import { ApiContext } from '../contexts/apicontext';
import { useFieldsteadApi } from '../hooks/useFieldsteadApi';
import { AuthenticationInfo } from '../contexts/authcontext';
import { IContactDetails, DocRequestType } from '../application/models';

import { PersonDetails } from './persondetail';
import { CompanyDetails } from './companydetail';

import { Grid, CircularProgress, Typography, Box, Button, Chip, makeStyles, createStyles, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faPrint, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { DisplayTags } from './tags/entitydisplay';
import { useAuthenticator } from '@aws-amplify/ui-react';

export interface IContactDetailsProps {
    contactid: string | null;
    editMode: () => void;
    copyMode: () => void;
    closeContact: () => void;
    requestDelete: (id: string | null) => void;
    isupdated: boolean;
}

export interface IContactDetailsState {
    isloading: boolean;
    contactdetails: IContactDetails | null;
}

const getDefaultContactState: () => IContactDetailsState = () => {
    return {
        isloading: true,
        contactdetails: null
    };
}

function showFile(filename: string, blob: Blob | null, contenttype: string): void {
    if (blob != null) {
        var newBlob = new Blob([blob], { type: contenttype });

        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //    window.navigator.msSaveOrOpenBlob(newBlob);
        //    return;
        //}

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }
}

export const ContactDetails: React.FC<IContactDetailsProps> = (props, context) => {
    const classes = useStyles();
    const { user } = useAuthenticator((context) => [context.user]);
    const { GetUserInfo, RequestVCard, RequestDocument, FetchContact } = useFieldsteadApi();
    //const api: RestApiRepository = useContext(ApiContext);
    const [contactState, setContactState] = useState(getDefaultContactState());
    const [copymode, setCopyMode] = useState<boolean>(false);
    //let auth: AuthenticationInfo | null = GetUserInfo();
    const [auth, setAuth] = useState<AuthenticationInfo | null>(null);

    useEffect(() => {
        GetUserInfo().then((auth) => {
            if (auth != null) {
                setAuth(auth);
            }
            else {
                setAuth(null);
            }
        })
    }, [user]);

    const displayDocument: (result: { success: boolean, filename: string, file: Blob | null }, contenttype: string) => void = (result, contenttype) => {
        showFile(result.filename, result.file, contenttype);
    }

    const getVCF: () => void = () => {
        if (contactState.contactdetails != null && contactState.isloading == false) {
            RequestVCard({
                ids: [contactState.contactdetails.id],
                fromDate: "",
                toDate: "",
                mode: DocRequestType.vCard,
                routefilter: null,
                flags: [],
                search: ""
            }).then((result) => { displayDocument(result, "text/directory"); });
        }
    }

    const printContact: () => void = () => {
        if (contactState.contactdetails != null && contactState.isloading == false) {
            RequestDocument({
                ids: [contactState.contactdetails.id],
                fromDate: "",
                toDate: "",
                mode: DocRequestType.ContactDetail,
                routefilter: null,
                flags: [],
                search: ""
            }).then((result) => { displayDocument(result, "text/html"); });
        }
    }

    useEffect(() => {
        if (props.contactid != null) {
            FetchContact(props.contactid).then((data) => {
                setContactState({ ...contactState, isloading: false, contactdetails: data });
            });
        }
    }, [props.contactid]);

    //useEffect(() => {
    //    if (contactState.isloading == false && contactState.contactdetails != null) {

    //    }
    //}, [contactState.isloading]);

    let details: IContactDetails | null = contactState.contactdetails;

    return (
        <Grid container alignItems="center">
            {contactState.isloading ? null : <Grid item container xs={12} justifyContent='space-around' alignItems='center' className={classes.funcBar}>
                    <Button onClick={props.closeContact} startIcon={<FontAwesomeIcon icon={faArrowLeft} />} variant='contained' color='primary'><Box fontSize='.8em' component="span">Back</Box></Button>
                    <Button onClick={printContact} startIcon={<FontAwesomeIcon icon={faPrint} />} variant='contained' color='primary'><Box fontSize='.8em' component="span">Print Contact</Box></Button>
                    <Button onClick={getVCF} startIcon={<FontAwesomeIcon icon={faAddressBook} />} variant='contained' color='primary'><Box fontSize='.8em' component="span">Get vCard</Box></Button>
                </Grid>}
            {props.isupdated == false ? null : <Chip color='secondary' label={<Typography><Box fontSize='.75em' component='span'>Changes Saved</Box></Typography>} />}
            {contactState.isloading ? <Grid item container xs={12} justifyContent="center"><CircularProgress /></Grid>
                : details != null ?
                    details.displaytype == "person" ? <Grid item xs={12}><PersonDetails details={details} /></Grid> : <Grid item xs={12}><CompanyDetails details={details} /></Grid>
                    : null}
            {/*contactState.isloading == false && details != null && auth.checkGroups("directory") ?
                <Grid item xs={12}>
                    <DisplayTags entityid={details.id} entitytype="CONTACT" />
                </Grid> : null */}
            {contactState.isloading == false && details != null && auth != null && auth.checkGroups("directory") ?
                <React.Fragment>
                    <Grid item container sm={2} xs={12} className={classes.bottomFuncBar} alignContent="flex-start"><Button onClick={() => { setCopyMode(false); props.editMode(); }} variant='contained' color='primary'>Edit Contact</Button></Grid>
                    <Grid item container sm={8} xs={12} className={classes.bottomFuncBar} alignContent="flex-start"><Button onClick={() => { setCopyMode(true); props.copyMode(); }} variant='contained' color='primary'>Copy Contact</Button></Grid>
                    {details.id != "" ?
                        <Grid item container sm={2} xs={12} className={classes.bottomFuncBar} alignContent="flex-start"><Button onClick={() => props.requestDelete(props.contactid)} variant='contained' className={classes.dangerButton}>Delete Contact</Button></Grid>
                        : null}
                </React.Fragment> : null}
        </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        funcBar: {
            marginBottom: '20px',
            padding: '20px'
        },
        bottomFuncBar: {
            paddingTop: '30px',
            paddingBottom: '15px'
        },
        dangerButton: {
            backgroundColor: theme.palette.danger.main,
            color: theme.palette.danger.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.danger.dark
            }
        }
    }));