import React, { useState, useContext, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, GetDataPath, GridReadyEvent, GridApi, ColumnApi, SelectionChangedEvent, RowNode, ValueFormatterParams, IFilterComp, ValueGetterParams } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '../../styles/gridstyles.scss';

import { Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
//import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { LogRowActionCellRenderer, DateCellRenderer, MultiLineRenderer } from './listitemactions';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
import { IMailLog, GetDefaultMailLog } from '../../application/models';

interface IMailLogListProps {
    startdate: Date | null;
    enddate: Date | null;
    updateEntry: (item: IMailLog) => void;
    deleteEntry: (id: number) => void;
    setLoading: () => void;
    endLoading: () => void;
    //Pass in functions here for things like running reports that will need to work off of a supplied list.
    //This control is going to have the API functionality for fetching values.
}

interface IMailLogState {
    entries: Array<IMailLogRowElement>;
    isLoading: boolean;
    isSaving: boolean;
    colapi: ColumnApi | null;
    activeEntry: IMailLog | null;
}

export interface IMailLogRowElement extends IMailLog {
    updateentry: () => void;
    deleteentry: () => void;
}

const dateConverter: (p: ValueGetterParams) => Date | null = (p) => {
    if (p != null && p.data != null && p.data.date != null) {
        return new Date(p.data.date);
    }
    return null;
}

export const MailLogList: React.FC<IMailLogListProps> = (props, context) => {
    const classes = useStyles();
    const { FetchMailLog } = useFieldsteadApi();
   // const api = useContext(ApiContext);
    const [state, setState] = useState<IMailLogState>({ entries: [], isLoading: false, isSaving: false, colapi: null, activeEntry: null });

    //const updateEntry: (data: IMailLog) => void = (data) => {
    //    setState({ ...state, activeEntry: data });
    //}
    
    //const deleteEntry: (data: IMailLog) => void = (data) => {
    //    api.DeleteMailItem(data.id || -1, () => { setState({ ...state, isLoading: true }); });
    //}

    const updateList: (data: Array<IMailLog>) => void = (data) => {
        setState({
            ...state, entries: data.map((d, i) => {
                return {
                    ...d,
                    updateentry: () => {
                        if (d != null) {
                            props.updateEntry(d);
                            setState({ ...state, isLoading: true });
                        }
                    },
                    deleteentry: () => {
                        if (d.id != null) {
                            props.deleteEntry(d.id);
                            setState({ ...state, isLoading: true });
                        }
                    }
                };
            }), isLoading: false
        });
        props.endLoading();
    }

    const onGridReady: (params: GridReadyEvent) => void = (params) => {
        setState({ ...state, colapi: params.columnApi });
    }


    useEffect(() => {
        if (state.isLoading) {
            props.setLoading();
            if (props.startdate != null && props.enddate != null) {
                FetchMailLog(props.startdate, props.enddate).then((response) => { updateList(response); });
            }
            else {
                updateList([]);
            }
        }
    }, [state.isLoading]);


    useEffect(() => {
        if (state.isLoading == false) {
            setState({ ...state, isLoading: true });
        }
    }, [props.startdate, props.enddate]);

    const getMailType: (params: ValueGetterParams) => string = (params) => {
        switch (params.data.type) {
            case "I":
                return "Inv.";
            case "G":
                return "Mail";
            case "T":
                return "Gift";
            case "C":
                return "Cmas";
            case "B":
                return "Bday";
            default:
                return "";
        }
    }


    const columnDefinitions: Array<ColDef | ColGroupDef> = [
        { cellRenderer: "LogRowActionCellRenderer", headerName: "", width: 100, suppressAutoSize: true },
        { field: 'type', headerName: "Type", filter: "agSetColumnFilter", sortable: true, width: 80, suppressAutoSize: true, valueGetter: getMailType },
        { field: 'category', headerName: "Category", filter: "agSetColumnFilter", sortable: true, width: 100, suppressAutoSize: true },
        { field: 'folder', headerName: "Folder", filter: "agSetColumnFilter", sortable: true, width: 125, suppressAutoSize: true },
        { field: 'from', headerName: "From", filter: "agTextColumnFilter", width: 125, sortable: true, autoHeight: true, cellStyle: { 'white-space': 'normal', 'text-align': 'left' } },
        { field: 'receiveddate', headerName: "Date", filter: "agDateColumnFilter", width: 125, sortable: true, suppressAutoSize: true, cellRenderer: "DateCellRenderer" },
        { field: 'comment', headerName: "Comment", sortable: false, filter: "agTextColumnFilter", width: 270, autoHeight: true, cellRenderer:"MultiLineRenderer", cellStyle: { 'white-space': 'normal', 'text-align': 'left' } },
        { field: 'sent', headerName: "Sent To", filter: "agSetColumnFilter", sortable: true, width: 100, suppressAutoSize: true },
        { field: 'routed', headerName: "Routed To", filter: "agSetColumnFilter", sortable: true, width: 125, suppressAutoSize: true },
    ];

    const componentRenderers = {
        LogRowActionCellRenderer: LogRowActionCellRenderer,
        DateCellRenderer: DateCellRenderer,
        MultiLineRenderer: MultiLineRenderer
    }

    return (
        <div className={`${classes.contentWindow} ag-theme-balham`}>
            <AgGridReact
                rowData={state.entries}
                columnDefs={columnDefinitions}
                onSelectionChanged={() => { }}
                frameworkComponents={componentRenderers}
                rowSelection="multiple"
                domLayout='autoHeight'
                onGridReady={onGridReady} />
        </div>
    );


}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '100%'
        },
        titleLine: {
            flexGrow: 0,
            flexShrink: 0,
            height: 'auto'
        },
        contentWindow: {
            width: "100%"
        },
        gridButtonBar: {
            margin: '0px'
        }
    }));