import { Router, Route, Routes, Link as RouterLink, BrowserRouter, useLocation } from 'react-router-dom';
import { Link, Grid, makeStyles, createStyles, Theme, Typography, Button, Box } from '@material-ui/core';
import { Stub } from './stub';
import { UserAdmin } from './admin/main';
import { MailLog } from './maillog/main';
import { Directory } from './directory';
import { Periodicals } from './periodicals/main';
import { MailingLists } from './directory/mailinglists';
import { TagManager } from './tags/manager';
import React, { useState, useContext, useEffect } from 'react';
import { Authenticator, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
//import { AuthenticationInfo, AuthContext } from '../contexts/authcontext';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../hooks/useFieldsteadApi';
import Auth from '@aws-amplify/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../content/fieldstead_logo.png';
import { browserhistory } from '../application/browserhistory';
import { AuthenticationInfo } from '../contexts/authcontext';

export interface INavigationProps {

}

interface INavigationState {
    section: string;
}

function parseSection(path: string): string {
    let parts: Array<string> = path.split('/');
    if (parts.length < 2) {
        return "directory";
    }
    else {
        return parts[1] != "" ? parts[1] : "directory";
    }
}

export const NavUnit: React.FC<INavigationProps> = (props, context) => {
    const classes = useStyles();
    const location = useLocation();
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const [auth, setAuth] = useState<AuthenticationInfo | null>(null);

    const { GetUserInfo } = useFieldsteadApi();

    useEffect(() => {
        GetUserInfo().then((auth) => {
            if (auth != null) {
                setAuth(auth);
            }
            else {
                setAuth(null);
            }
        })
    }, [user]);
    
    let section: string = location.pathname;

    const signUserOut: () => void = () => {
        //Auth.signOut()
        //    .then(data => console.log(data))
        //    .catch(err => console.log(err));
    }
    //let auth: AuthenticationInfo = useContext(AuthContext);

    return (
            <div>
                <header>
                    <nav>
                        <Grid container direction="row" className={classes.navBar} alignItems='stretch'>
                            <Grid item xs={2}>
                                <img src={logo} height='75px' />
                            </Grid>
                            <Grid item xs={4}></Grid>
                        <Grid item container xs={1} alignContent='center' className={parseSection(location.pathname) == "directory" ? classes.selectedItem : ''}>
                                <Grid item xs={12}>
                                <Link component={RouterLink} to="/" className={parseSection(location.pathname) == "directory" ? classes.selectedItem : ''}><Typography>Directory</Typography></Link>
                                </Grid>
                        </Grid>
                        <Grid item container xs={1} alignContent='center'>
                            <Grid item xs={12}>
                                <Link href='https://fieldstead.soutronglobal.net/Library/Catalogues/Search.aspx' target='_blank'><Typography>Library</Typography></Link>
                            </Grid>
                        </Grid>
                        {(auth != null && auth.checkGroups("maillog")) ?
                            <React.Fragment>
                                <Grid item container xs={1} alignContent='center' className={parseSection(location.pathname) == "maillog" ? classes.selectedItem : ''}>
                                    <Grid item xs={12}>
                                        <Link component={RouterLink} to="/maillog/" className={parseSection(location.pathname) == "maillog" ? classes.selectedItem : ''}><Typography>Mail</Typography></Link>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} container alignContent='center' className={parseSection(location.pathname) == "periodicals" ? classes.selectedItem : ''}>
                                    <Grid item xs={12}>
                                        <Link component={RouterLink} to="/periodicals/" className={parseSection(location.pathname) == "periodicals" ? classes.selectedItem : ''}><Typography>Periodicals</Typography></Link>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            : null}
                            {(auth != null && auth.checkGroups("users")) ?
                            <Grid item xs={1} container alignContent='center' className={parseSection(location.pathname) == "users" ? classes.selectedItem : ''}>
                                    <Grid item xs={12}>
                                    <Link component={RouterLink} to="/users/" className={parseSection(location.pathname) == "users" ? classes.selectedItem : ''}><Typography>Admin</Typography></Link>
                                    </Grid>
                                </Grid> : null}
                            <Grid item xs={1} container alignContent='center'>
                                <Grid item xs={12}>
                                    <Button onClick={signOut} variant='contained' color='secondary' size="small" startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}><Typography><Box fontSize='.6em' component="span">Sign Out</Box></Typography></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </nav>
                </header>
                <Grid container alignItems="center" justifyContent="space-around">
                <Grid item xs={11}>
                    <Routes>
                        <Route path="/" element={<Directory anchor=""/>} />
                        <Route path="/maillog/" element={<MailLog />} />
                        <Route path="/periodicals/" element={<Periodicals />} />
                        <Route path="/users/" element={<UserAdmin />} />
                        <Route path="/lists/" element={<MailingLists />} />
                        <Route path="/tags/" element={ <TagManager /> } />
                    </Routes>
                    </Grid>
                </Grid>
            </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navBar: {
            justifyContent: "space-around", 
            textAlign: 'center',
            padding: '3px',
            marginBottom: '20px',
            borderBottom: '2px solid #dddddd',
            borderTop: '2px solid #dddddd',
            backgroundColor: '#ffffff'
        },
        selectedItem: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main
        }
    }));