import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { CognitoUserSession, ICognitoUserSessionData } from 'amazon-cognito-identity-js';
import { NavUnit } from './components/navigation';
import { Authenticator, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { AuthenticationInfo } from './contexts/authcontext';
import { FieldsteadAPIConfigProvider } from './contexts/fieldsteadapicontext';

import logo from './content/fieldstead_logo_sm.png';

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        danger: Palette['primary'];
        safe: Palette['primary'];
    }
    interface PaletteOptions {
        danger: Palette['primary'];
        safe: Palette['primary'];
    }
}

Amplify.configure(aws_exports);

interface IAppProps {
    apiendpointurl: string;
}

interface IAppFormState {
    isLoaded: boolean;
}

const mainTheme = createTheme({
    typography: {
        fontFamily: [
            'itc-american-typewriter, serif;'
        ].join(','),
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        danger: { main: red[500], light: red[300], dark: red[700], contrastText: "#ffffff" },
        safe: { main: green[500], light: green[300], dark: green[700], contrastText: "#ffffff" },
        primary: { main: '#0227b3', light: '#3452c2', dark: '#3452c2', contrastText: "#ffffff" },
        secondary: {main: '#ffd534', light: '#ffdd5c', dark: '#b29524', contrastText: "#000000"}
    }
});

const components = {
    Header() {
        const { tokens } = useTheme();
        return (
            <View textAlign="center" padding={0}>
                <img src={ logo } />
            </View>
            );
    }
}

export const App: React.FC<IAppProps> = (props) => {
    let [formstate, setformstate] = React.useState<IAppFormState>({ isLoaded: false });

    const { user } = useAuthenticator((context) => [context.user]);

    const getToken: (u: any) => Promise<string> = (u) => {
        return new Promise<string>((resolve, reject) => {
            if (u != null) { 
                u.getSession((err: (Error | null), session: (CognitoUserSession | null)) => {
                    if (session != null) {
                        let token: string = session.getIdToken().getJwtToken();
                        resolve(token);
                    }
                    else {
                        reject("Session undefined");
                    }
                });
            }
            else {
                reject("Unauthenticated");
            }
        });
    }

    return (
        <Authenticator hideSignUp={true} components={components}>
            {({ signOut, user }) => (
                <div className="App">
                    <FieldsteadAPIConfigProvider endpointurl={props.apiendpointurl} getaccesstoken={() => { return getToken(user); }}>
                        <ThemeProvider theme={mainTheme}>
                            <NavUnit />
                        </ThemeProvider>
                    </FieldsteadAPIConfigProvider>
                </div>
            )}
        </Authenticator>
    );
}
