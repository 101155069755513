import React, { useState, useEffect, useContext } from 'react';

import { Grid, TextField, Typography, Box, Button, createStyles, makeStyles, Theme, Select, OutlinedInput, Dialog, CircularProgress, DialogTitle, DialogContent } from '@material-ui/core';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
import { FlagData } from '../../application/restapirepository';
import { IDocumentRequest, DocRequestType } from '../../application/models';

const styles = {
};

export interface IMailingListProps {

}

interface IMailingListState {
    searchstring: string;
    flaglist: string;
    isloading: boolean;
    flagreporttype: DocRequestType;
    searchreporttype: DocRequestType;
}

function showFile(filename: string, blob: Blob | null): void {
    if (blob != null) {
        var newBlob = new Blob([blob], { type: "text/html" });

        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //    window.navigator.msSaveOrOpenBlob(newBlob);
        //    return;
        //}

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }
}


export const MailingLists: React.FC<IMailingListProps> = (props, context) => {
    //const api = useContext(ApiContext);
    const classes = useStyles();
    const [formstate, setFormState] = useState<IMailingListState>({ searchstring: "", flaglist: "", isloading: false, flagreporttype: DocRequestType.ContactsClassic, searchreporttype: DocRequestType.ContactsClassic });
    const [searchitems, setsearchitems] = useState<Array<string>>([]);
    const { RequestDocument, GetStates, GetInternational, GetPersonal, GetOtherOptions } = useFieldsteadApi();

    const displayDocument: (result: { success: boolean, filename: string, file: Blob | null }) => void = (result) => {
        showFile(result.filename, result.file);
        setFormState({ ...formstate, isloading: false });  
    }

    const toggleSearchFlag: (flag: string) => void = (flag) => {
        let ix: number = searchitems.indexOf(flag);
        let itms = [...searchitems];
        if (ix > -1) {
            if (itms.length == 1) {
                itms = [];
            }
            else {
                itms.splice(ix, 1);
            }
        }
        else {
            itms.push(flag);
        }
        setsearchitems(itms);
    }

    const updateSearchFlags: (flags: string) => void = (flags) => {
        let flgs: Array<string> = flags == "" ? [] : flags.split(',').map((f) => {return f.trim(); });
        setsearchitems(flgs);
    }

    /*
    const requestList: (flags: string) => void = (flags) => {
        requestModedList(flags, DocRequestType.Contacts);
    }
    */

    const requestModedList: (mode: DocRequestType) => void = (mode) => {
        let reqarray: Array<string> = searchitems;
        if (reqarray.length > 0) {
            let req: IDocumentRequest = {
                ids: null,
                fromDate: "",
                toDate: "",
                mode: mode,
                routefilter: null,
                flags: reqarray,
                search: ""
            };
            RequestDocument(req).then((response) => { displayDocument(response); });
            setFormState({ ...formstate, isloading: true });
        }
    }

    const requestSearchList: (search: string, mode: DocRequestType) => void = (search, mode) => {
        if (search != null && search != "") {
            let req: IDocumentRequest = {
                ids: null,
                fromDate: "",
                toDate: "",
                mode: mode,
                routefilter: null,
                flags: [],
                search: formstate.searchstring
            };
            RequestDocument(req).then((response) => { displayDocument(response); });
            setFormState({ ...formstate, isloading: true });
        }
    }

    let states: Array<FlagData> = GetStates();    
    let international: Array<FlagData> = GetInternational();
    let personal: Array<FlagData> = GetPersonal();
    let generalopts: Array<FlagData> = GetOtherOptions();

    return (
        <Grid container spacing={2}>
            <Dialog open={formstate.isloading}>
                <DialogTitle>Loading document request...</DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid item xs={12} className={classes.titleBar}><Typography>Travel Local</Typography></Grid>
            <Grid item xs={12}>
                <Box>
                    {states.map((v, i) => {
                        return (
                            <Button variant='outlined' className={classes.statebuttons} onClick={() => { toggleSearchFlag('TL' + v.flag); }}><Typography><Box component='span' fontSize='.7em'>{v.name}</Box></Typography></Button>
                            );
                    })}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    {international.map((v, i) => {
                        return (
                            <Button variant='outlined' className={classes.abroadbuttons} onClick={() => { toggleSearchFlag('TL' + v.flag); }}><Typography><Box component='span' fontSize='.7em'>{v.name.toUpperCase()}</Box></Typography></Button>
                        );
                    })}
                </Box>
            </Grid>
            <Grid item xs={12} className={classes.titleBar}><Typography>Chauffeurs</Typography></Grid>
            <Grid item xs={12}>
                <Box>
                    {states.map((v, i) => {
                        return (
                            <Button variant='outlined' className={classes.statebuttons} onClick={() => { toggleSearchFlag('DV' + v.flag); }}><Typography><Box component='span' fontSize='.7em'>{v.name}</Box></Typography></Button>
                        );
                    })}
                </Box>
            </Grid>
            <Grid item xs={12} className={classes.titleBar}><Typography>Personal Addresses</Typography></Grid>
            <Grid item container xs={12} justifyContent='space-around'>
                    {personal.map((v, i) => {
                        return (
                            <Grid item xs={12} sm={4}>
                                <Button fullWidth variant='outlined' onClick={() => { toggleSearchFlag('TVL' + v.flag); }}><Typography><Box component='span' fontSize='.7em'>{v.name}</Box></Typography></Button>
                            </Grid>
                        );
                    })}
            </Grid>
            <Grid item xs={12} className={classes.titleBar}><Typography>Other</Typography></Grid>
            <Grid item xs={12}>
                <Box justifyItems='center'>
                    {generalopts.map((v, i) => {
                        return (
                            <Button variant='outlined' className={classes.otherbuttons} onClick={() => { toggleSearchFlag(v.flag); }}><Typography><Box component='span' fontSize='.7em'>{v.name}</Box></Typography></Button>
                        );
                    })}
                </Box>
            </Grid>
            <Grid item container xs={12} md={10}>
                <Grid item xs={12}><Typography>Enter a comma-separated list of flags you would like to print</Typography></Grid>
                <Grid item xs={8}><TextField fullWidth variant='outlined' size="small" value={searchitems.join(',')} onChange={(e) => { if (e.target != null) { updateSearchFlags(e.target.value); } } } /></Grid>
                <Grid item xs={2}><Select fullWidth native input={<OutlinedInput classes={{ input: classes.reporttypeselect }} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormState({ ...formstate, flagreporttype: e.target.value }); }} />}><option value={DocRequestType.ContactsClassic}>classic</option><option value={DocRequestType.Contacts}>fco directory</option><option value={DocRequestType.Salutations}>salutations</option></Select></Grid>
                <Grid item xs={1}><Button variant='contained' color='primary' disabled={searchitems.length == 0} onClick={() => { requestModedList(formstate.flagreporttype); }}>Print</Button></Grid>
            </Grid>
            <Grid item container xs={12} md={10}>
                <Grid item xs={12}><Typography>Or search</Typography></Grid>
                <Grid item xs={8}><TextField fullWidth variant='outlined' size="small" value={formstate.searchstring} onChange={(e) => { if (e.target != null) { setFormState({ ...formstate, searchstring: e.target.value }); } }}/></Grid>
                <Grid item xs={2}><Select fullWidth native input={<OutlinedInput classes={{ input: classes.reporttypeselect }} />} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormState({ ...formstate, searchreporttype: e.target.value }); }}><option value={DocRequestType.ContactsClassic}>classic</option><option value={DocRequestType.Contacts}>fco directory</option><option value={DocRequestType.Salutations}>salutations</option></Select></Grid>
                <Grid item xs={1}><Button variant='contained' color='primary' disabled={formstate.searchstring == null || formstate.searchstring == ""} onClick={() => { requestSearchList(formstate.searchstring, formstate.searchreporttype); }}>Print</Button></Grid>
            </Grid>
        </Grid>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        statebuttons: {
            width: '40px',
            minWidth: '0px',
            padding: '5px',
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: '0'            
        },
        abroadbuttons: {
            width: '120px',
            minWidth: '0px',
            padding: '5px',
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: '0'  
        },
        otherbuttons: {
            width: '25%',
            minWidth: '200px',
            padding: '5px',
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: '0'
        },
        personalbuttons: {
            width: '100%',
            minWidth: '0px',
            padding: '5px',
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: '0'
        },
        titleBar: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            textAlign: 'center',
            borderRadius: '5px',
            marginTop: '20px'
        },
        reporttypeselect: {
            padding: '10px'
        }
    }));