import { Button, ButtonGroup, Checkbox, Chip, createStyles, FormControlLabel, Grid, makeStyles, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import React, { useReducer } from 'react';
import { ITag } from '../../application/models';
import { ChromePicker, HuePicker, MaterialPicker, SliderPicker } from 'react-color';
import { faBan, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IEditTagsProps {
    tag: ITag;
    save: (t: ITag) => void;
    cancel: (t: ITag) => void;
}

interface IEditTagFormState {
    id: string;
    code: string;
    name: string;
    color: string;
    darkfont: boolean;
    priority: number;
    active: boolean;
}

function createFormState(tag: ITag): IEditTagFormState {
    return {
        id: tag.id,
        code: tag.code,
        name: tag.name,
        color: tag.color,
        darkfont: tag.darkfont,
        priority: tag.priority,
        active: tag.active
    };
}

export const EditTag: React.FC<IEditTagsProps> = (props, context) => {
    const classes = useStyles();
    const [formstate, formreducer] = useReducer(EditReducer, createFormState(props.tag));
    return (
        <Grid container justify='space-around' style={{borderStyle: 'solid', borderWidth:'1px', borderColor: 'black', padding:'8px'}}>
            <Grid item container xs={3} justify='space-between'>
                <Grid item xs={12}>
                    <Tooltip title={ formstate.name }>
                        <Chip label={formstate.code} style={{ background: formstate.color, color: formstate.darkfont ? 'black' : 'white' }} />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth value={formstate.code} variant='outlined' label='Code' size='small' onChange={(e) => formreducer({ type: "code", payload: e.target.value })} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth value={formstate.name} variant='outlined' label='Name' size='small' onChange={(e) => formreducer({ type: "name", payload: e.target.value })} />
                </Grid>
            </Grid>
            <Grid item container xs={3} spacing={2}>
                <Grid item container xs={12} justify="center">
                    <Grid item>
                        <HuePicker width="200px" color={formstate.color} onChange={(color) => formreducer({ type: "color", payload: color.hex })} />
                    </Grid>
                </Grid>
                <Grid item container xs={12} justify="center">
                    <Grid item>
                        <MaterialPicker color={formstate.color} onChange={(color) => formreducer({ type: "color", payload: color.hex })} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={2} justify='space-between'>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={props.tag.darkfont} onChange={() => { formreducer({type: "darkfont", payload: !formstate.darkfont }) }} />} label="Dark Font?" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth value={formstate.priority} variant='outlined' label='Priority' size='small' onChange={(e) => formreducer({ type: "priority", payload: parseInt(e.target.value) })} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={props.tag.active} onChange={() => { formreducer({ type: "active", payload: !formstate.active }) }} />} label="Active?" />
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <ButtonGroup variant='contained'>
                    <Button onClick={() => props.save(formstate)} className={classes.confirmButton}><FontAwesomeIcon icon={faSave} /></Button>
                    <Button onClick={() => props.cancel(props.tag)} className={classes.dangerButton}><FontAwesomeIcon icon={faBan} /></Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dangerButton: {
            backgroundColor: theme.palette.danger.main,
            color: theme.palette.danger.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.danger.dark
            }
        },
        confirmButton: {
            backgroundColor: theme.palette.safe.main,
            color: theme.palette.safe.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.safe.dark
            }
        }
    }));

type EditAction = { type: "code", payload: string }
    | { type: "name", payload: string }
    | { type: "color", payload: string }
    | { type: "darkfont", payload: boolean }
    | { type: "priority", payload: number }
    | { type: "active", payload: boolean };

function EditReducer(state: IEditTagFormState, action: EditAction): IEditTagFormState {
    switch (action.type) {
        case "code":
            return { ...state, code: action.payload };
        case "name":
            return { ...state, name: action.payload };
        case "color":
            return { ...state, color: action.payload };
        case "darkfont":
            return { ...state, darkfont: action.payload };
        case "priority":
            return { ...state, priority: action.payload };
        case "active":
            return { ...state, active: action.payload };
        default:
            return state;
    }
}