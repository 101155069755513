

export const MakeDateString: (d: Date) => string = (d) => {
    let m: string = d.getMonth() < 9 ? "0" + (d.getMonth() + 1).toString() : (d.getMonth() + 1).toString();
    let dd: string = d.getDate() < 10 ? "0" + d.getDate().toString() : d.getDate().toString();
    return m + "-" + dd + "-" + d.getFullYear().toString();
}


export const MakeMonthYearString: (d: Date) => string = (d) => {
    let m: string = d.getMonth() < 9 ? "0" + (d.getMonth() + 1).toString() : (d.getMonth() + 1).toString();
    return m + "-" + d.getFullYear().toString();
}

export const MakeShortMonthYearString: (d: Date, fromUtc?: boolean | undefined) => string = (d, fromUtc) => {
    if (fromUtc) {
        let u: string = d.getUTCMonth() < 9 ? "0" + (d.getUTCMonth() + 1).toString() : (d.getUTCMonth() + 1).toString();
        return u + "/" + d.getUTCFullYear().toString().substring(2);
    }
    let m: string = d.getMonth() < 9 ? "0" + (d.getMonth() + 1).toString() : (d.getMonth() + 1).toString();
    return m + "/" + d.getFullYear().toString().substring(2);
}

export const ExtractLines: (s: string) => Array<string> = (s) => {
    let outstrings: Array<string> = s.split(/<br>|<BR>|<br \/>|<BR \/>|<br\/>|<BR\/>|\r?\n/);
    return outstrings;
}
