import React, { Component, Fragment } from 'react';
import { Paper, Grid, makeStyles, createStyles, Theme, Button, ButtonGroup,Typography } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

import { ExtractLines } from '../../application/helperfuncs';

import { IMailLog } from '../../application/models';
import { IMailLogRowElement } from './list';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';


export const LogRowActionCellRenderer: React.FC<ICellRendererParams> = (props, context) => {
    let val: IMailLogRowElement = props.data;
    return (
        <div className="gridbuttonbar">
            <button className="gridiconbutton caution" style={{ margin: "1px" }} onClick={(e) => { val.updateentry(); }}><FontAwesomeIcon icon={faEdit} /></button>
            <button className="gridiconbutton danger" style={{ margin: "1px" }} onClick={(e) => { val.deleteentry(); }}><FontAwesomeIcon icon={faTrash} /></button>
        </div>
        );
}

export const MultiLineRenderer: React.FC<ICellRendererParams> = (props, context) => {
    if (props.data.comment == null) return null;
    let lines: Array<string> = ExtractLines(props.data.comment);
    if (lines.length == 1) {
        return (<Fragment>{lines[0]}</Fragment>);
    }
    
    return (
            <Fragment>
            {ExtractLines(props.data.comment).map((t, i) => {
                return (
                    <Fragment>{t}<br /></Fragment>
                );
            })}
            </Fragment>
        );
} 

interface DateRowState {
    date: Date;
}


export const DateCellRenderer: (params: ICellRendererParams) => string = (params) => {
    let val: IMailLogRowElement = params.data;
    if (val != null && val.receiveddate != null) {
        let d: Date = new Date(val.receiveddate);
        let m: string = d.getMonth() < 9 ? "0" + (d.getMonth() + 1).toString() : (d.getMonth() + 1).toString();
        let dd: string = d.getDate() < 10 ? "0" + d.getDate().toString() : d.getDate().toString();
        return m + "-" + dd + "-" + d.getFullYear().toString();
    }
    return "";

}

