import React, { useEffect, useContext, useState, Fragment } from 'react';
import { Grid, CircularProgress, Typography, Box, Hidden, makeStyles, Theme, createStyles } from '@material-ui/core';

import { IContactDetails } from '../application/models';
//import { ApiContext } from '../contexts/apicontext';
import { useFieldsteadApi } from '../hooks/useFieldsteadApi';
//import { RestApiRepository } from '../application/restapirepository';
import Image from 'material-ui-image';
import { AddressDetails } from './contacts/contactaddress';

import { ExtractLines } from '../application/helperfuncs';

interface IPersonDetailsProps {
    details: IContactDetails;
}

export const PersonDetails: React.FC<IPersonDetailsProps> = (props, context) => {
    let classes = useStyles();
    let details: IContactDetails = props.details;
    const { GetContactImageUrl } = useFieldsteadApi();
    //let api: RestApiRepository = useContext(ApiContext);
    return (
        <Grid container>
            <Grid container item xs={12}>
                {ExtractLines(details.entityname).map((s, i) => {
                    return (
                        <Grid item xs={12} key={'jt' + i.toString()}>
                            <Typography variant='h4' align="left" color="primary">{s}</Typography>
                        </Grid>
                    );
                })}
            </Grid>
            {details.children != null && details.children != "" ? <Grid item xs={12}><Typography variant='h5' align='left' className={classes.children}>{details.children}</Typography></Grid> : null}
            <Grid container item xs={12} md={8} spacing={1} className={classes.detailspane}>
                {details.hasimage ?
                    <Hidden mdUp>
                        <Grid xs={12}>
                            <img src={GetContactImageUrl(details.id)} />
                        </Grid>
                    </Hidden> : null}

                {details.jobtitle != null && details.jobtitle != "" ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}>
                            <Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Job Title:</Box></Typography>
                        </Grid>
                        <Grid item container md={8} xs={12}>
                            {ExtractLines(details.jobtitle).map((s, i) => {
                                return (
                                    <Grid item xs={12} key={'jt' + i.toString()}>
                                        <Typography align="left">{s}</Typography>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid> : null}
                {details.companyName != null && details.companyName != "" ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}>
                            <Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Company Name:</Box></Typography>
                        </Grid>
                        <Grid item container md={8} xs={12}>
                            {ExtractLines(details.companyName).map((t, i) => {
                                return (
                                    <Grid item xs={12} key={'cn' + i.toString()}>
                                        <Typography align="left">{t}</Typography>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid> : null}
                {details.department != null && details.department != "" ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}>
                            <Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Department:</Box></Typography>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Typography align="left">{details.department}</Typography>
                        </Grid>
                    </Grid> : null}
                {details.phonenumbers.length > 0 ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Phone:</Box></Typography></Grid>
                        <Grid item container md={8} xs={12}>
                            {details.phonenumbers.map((p, i) => {
                                return (
                                    <Grid className={classes.spacedline} item container xs={12} key={'pn' + i.toString()}>
                                        {p.label != null && p.label != "" ? <Grid item xs={12}><Typography align="left">{p.label}:</Typography></Grid> : null}
                                        <Grid item xs={12}><a href={"tel:" + p.phonenumber}><Typography align="left">{p.phonenumber}</Typography></a></Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid> : null}
                {details.addresses.length > 0 ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Address:</Box></Typography></Grid>
                        <Grid item container md={8} xs={12} spacing={1}>
                            {details.addresses.map((a, i) => {
                                return (
                                    <AddressDetails address={a} index={i} />
                                );
                            })}
                        </Grid>
                    </Grid> : null}
                {details.emails.length > 0 ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Email:</Box></Typography></Grid>
                        <Grid item container md={8} xs={12}>
                            {details.emails.map((e, i) => {
                                return (
                                    <Grid className={classes.spacedline} item container xs={12} key={'email' + i.toString()}>
                                        {e.label != null && e.label != "" ? <Grid item xs={12}><Typography align="left">{e.label}:</Typography></Grid> : null}
                                        <Grid item xs={12}><a href={'mailto:' + e.email}><Typography align="left">{e.email}</Typography></a></Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid> : null}
                {details.relationships.length > 0 ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Relationships:</Box></Typography></Grid>
                        <Grid item container md={8} xs={12}>
                            {details.relationships.map((r, i) => {
                                return (
                                    <Grid className={classes.spacedline} item container xs={12} key={'relationship' + i.toString()}>
                                        {r.label != null && r.label != "" ? <Grid item xs={12}><Typography align="left">{r.label}:</Typography></Grid> : null}
                                        <Grid item xs={12}><Typography align="left">{r.name}</Typography></Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid> : null}
                {details.urls.length > 0 ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>URL:</Box></Typography></Grid>
                        <Grid item container md={8} xs={12}>
                            {details.urls.map((u, i) => {
                                return (
                                    <Grid className={classes.spacedline} item container xs={12} key={'url' + i.toString()}>
                                        {u.label != null && u.label != "" ? <Grid item xs={12}><Typography align="left">{u.label}:</Typography></Grid> : null}
                                        <Grid item xs={12}><a href={"http://" + u.url} target='_blank'><Typography align="left">{u.url}</Typography></a></Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid> : null}
                {(details.publicnotes != null && details.publicnotes != "") ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Print Notes:</Box></Typography></Grid>
                        <Grid item container md={8} xs={12}>
                            <Grid item xs={12}>
                                {ExtractLines(details.publicnotes).map((t, i) => {
                                    return (
                                        <Grid item xs={12} key={'pnt' + i.toString()}>
                                            <Typography align="left">{t}</Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid> : null}
                {(details.internalnotes != null && details.internalnotes != "") ?
                    <Grid item xs={12} container>
                        <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>View Only Notes:</Box></Typography></Grid>
                        <Grid item container md={8} xs={12}>
                            <Grid item xs={12}>
                                {ExtractLines(details.internalnotes).map((t, i) => {
                                    return (
                                        <Grid item xs={12} key={'int' + i.toString()}>
                                            <Typography align="left">{t}</Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid> : null}
                <Grid item xs={12} container>
                    <Grid item md={4} xs={12} className={classes.detaillabel}><Typography align="left"><Box fontWeight="fontWeightBold" component='span'>Last Updated:</Box></Typography></Grid>
                    <Grid item container md={8} xs={12}><Typography align="left">{details.lastupdate}</Typography></Grid>
                </Grid>
            </Grid>
            <Hidden smDown>
                <Grid item md={4}>
                    {details.hasimage ? <img src={GetContactImageUrl(details.id)} /> : null}
                </Grid>
            </Hidden>
        </Grid>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        detaillabel: {
            flex: '0 0 150px'
        },
        children: {
            paddingLeft: '50px',
            color: theme.palette.primary.light
        },
        detailspane: {
            marginTop: '50px'
        },
        spacedline: {
            paddingTop: '8px',
            paddingBottom: '8px'
        }
    }));