import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Box, Chip, createStyles, Grid, makeStyles, TextField, Theme, Typography, Button, CircularProgress, List, ListItem } from '@material-ui/core';
import React, { useContext, useEffect, useState, useReducer } from 'react';
import { IEntityTag, ITag } from '../../application/models';
//import { RestApiRepository } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
import { AuthenticationInfo } from '../../contexts/authcontext';
import { initializeSearch, tagSearchReducer, TagSearchAction } from './reducers/tagsearchreducer';
import { TagChip } from './tagchip';
import { useAuthenticator } from '@aws-amplify/ui-react';

interface IEntityTagsProps {
    entitytype: string;
    entityid: string;
}

interface IEntityTagsFormState {
    showChange: boolean;
    selectedTags: Array<ITag>;
}

export const EditEntityTags: React.FC<IEntityTagsProps> = (props, context) => {
    //const api: RestApiRepository = useContext(ApiContext);
    const { GetUserInfo, FetchTags, AddEntityTag, AddEntityTags, RemoveEntityTag, FetchEntityTags } = useFieldsteadApi();
    const [tagSearchState, tagSearchDispatch] = useReducer(tagSearchReducer, initializeSearch());

    const { user } = useAuthenticator((context) => [context.user]);
    const [auth, setAuth] = useState<AuthenticationInfo | null>(null);
    useEffect(() => {
        GetUserInfo().then((auth) => {
            if (auth != null) {
                setAuth(auth);
            }
            else {
                setAuth(null);
            }
        })
    }, [user]);

    let [entitytags, setEntityTags] = useState<Array<IEntityTag>>([]);

    let [formstate, setFormState] = useState<IEntityTagsFormState>({ showChange: false, selectedTags: [] });

    const classes = useStyles();

    const refreshTags: () => void = () => {
        tagSearchDispatch({ type: "Refresh" });
        var selected = entitytags.map(et => et.id);
        FetchTags().then((data) => { tagSearchDispatch({ type: "Loaded", payload: data.filter(d => selected.indexOf(d.id) < 0) }) });
    }

    const toggleTag: (tag: ITag) => void = (t) => {
        let tagind: number = formstate.selectedTags.indexOf(t);
        if (tagind > -1) {
            setFormState({ ...formstate, selectedTags: formstate.selectedTags.filter(t0 => t0 != t) });
        }
        else {
            setFormState({ ...formstate, selectedTags: [...formstate.selectedTags, t] });
        }
    }

    const submitTags: (tags: Array<ITag>) => void = (ts) => {
        tagSearchDispatch({ type: "Refresh" });
        AddEntityTags(ts, props.entityid, props.entitytype)
            .then((data) => {
                setEntityTags(data);
                return FetchTags();
            })
            .then((data) => {
                setFormState({ ...formstate, selectedTags: [] });
                tagSearchDispatch({ type: "Loaded", payload: tagSearchState.searchOptions });
            });
    }

    const addTag: (tag: ITag) => void = (t) => {
        tagSearchDispatch({ type: "Refresh" });
        AddEntityTag(t, props.entityid, props.entitytype)
            .then((data) => {
                setEntityTags(data);
                return FetchTags();
            })
            .then((data) => {
                tagSearchDispatch({ type: "Loaded", payload: tagSearchState.searchOptions });
            });
    }

    const removeTag: (entitytag: IEntityTag) => void = (t) => {
        tagSearchDispatch({ type: "Refresh" });
        RemoveEntityTag(t, props.entityid, props.entitytype)
            .then((data) => {
                setEntityTags(data);
            })
            .then((data) => {
                tagSearchDispatch({ type: "Loaded", payload: tagSearchState.searchOptions });
            });;
    }

    const executeSearch: (term: string) => void = (term) => {
        tagSearchDispatch({ type: "Search", payload: term });
    }

    const setEditMode: () => void = () => {
        setFormState({ ...formstate, showChange: !formstate.showChange });
    }

    useEffect(() => {
        FetchEntityTags(props.entitytype, props.entityid).then((data) => { setEntityTags(data); });
    }, []);

    useEffect(() => {
        refreshTags();
    }, []);

    return (tagSearchState.loading ?
        <CircularProgress />
        :
        <Grid container xs={12}>
            <Grid container item xs={12}>
                <Typography>Select tags and use <FontAwesomeIcon icon={faArrowRight} /> to save or click X to remove tag.</Typography>
            </Grid>
            <Grid item container xs={4}>
                <Grid item xs={12}>
                    <TextField fullWidth value={tagSearchState.searchValue} variant='outlined' label='Find' size='small' onChange={(e) => { executeSearch(e.target.value.toUpperCase()); }} />
                </Grid>
                <Grid item xs={12} container justify="space-between" className={classes.taglist}>
                    <List>
                        {tagSearchState.searchMatches.filter(t => entitytags.findIndex(et => et.code == t.code) < 0).sort((a, b) => { return ('' + a.code).localeCompare(b.code); }).map((t, i) => {
                            return (
                                <ListItem key={t.id + 'search'} selected={formstate.selectedTags.indexOf(t) > -1}><TagChip className={classes.tagchip} tag={t} onclick={() => { toggleTag(t); }} ondelete={null} /></ListItem>
                            );
                        })}
                    </List>
                </Grid>
            </Grid>
            <Grid item container xs={2} justifyContent='space-around' alignContent='space-around' alignItems='center'>
                <Button className={classes.tagbutton} size='small' variant='contained' color='primary' onClick={() => { submitTags(formstate.selectedTags); }}><FontAwesomeIcon icon={faArrowRight} /></Button>
            </Grid>
            <Grid container item xs={6} justify="space-between" alignContent='flex-start'>
                {entitytags.sort((a, b) => { return ('' + a.code).localeCompare(b.code); }).map((t, i) => {
                    return (
                        <TagChip tag={t} onclick={null} key={t.id} ondelete={() => { removeTag(t); }} className={classes.entitychip} />
                    );
                })}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlContainer: {
            minHeight: '60px'
        },
        displayBox: {
            borderWidth: '2px',
            borderColor: 'black',
            borderStyle: 'solid',
            borderRadius: '5px',
            backgroundColor: theme.palette.secondary.main,
            zIndex: 5
        },
        taglist: {
            maxHeight: '200px',
            overflowY: 'scroll'
        },
        tagbutton: {
            minWidth: '20px'
        },
        tagchip: {
            width: '150px',
            fontSize: '.6em'
        },
        entitychip: {
            width: '150px',
            fontSize: '.6em',
            margin: '3px'
        }
    }));