import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, Typography, DialogContentText, DialogContent, DialogActions, Button, Grid, Select, MenuItem, TextField } from '@material-ui/core';
import { ISubscription } from '../../application/models';
import { MakeDateString } from '../../application/helperfuncs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose, faTrash } from '@fortawesome/free-solid-svg-icons';

import { DangerButton } from '../controls/buttons';

export interface IDeactivatePeriodicalProps {
    open: boolean;
    subscription: ISubscription | null;
    deactivate: (data: ISubscription) => void;
    clearIssue: (date: string) => void;
    cancel: () => void;
}

export const DeactivatePeriodicalDialog: React.FC<IDeactivatePeriodicalProps> = (props, context) => {

    const deactivateSubscription: () => void = () => {
        if (props.subscription != null) {
            let savedate: Date = new Date(1, 1, 1);
            let savesub: ISubscription = {
                ...props.subscription,
                renew: savedate
            }
            props.deactivate(savesub);
        }
    }

    const removeIssue: (date: string) => void = (date) => {
        props.clearIssue(date);
    }

    if (props.subscription != null) {

        return (
            <Dialog open={props.open}>
                <DialogTitle><Typography>{"Deactivate " + props.subscription.name}</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Disable subscription or back out issues.
                </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={10}><Typography>Deactivate renewals for {props.subscription.name}</Typography></Grid>
                        <Grid item xs={2}><DangerButton onClick={deactivateSubscription} variant='contained'><FontAwesomeIcon icon={faTrash} /></DangerButton></Grid>
                    </Grid>
                    {props.subscription.received.length > 0 ?
                        <Grid container spacing={1}>
                            <Grid item xs={10}><Typography>Back out issue for {MakeDateString(new Date(props.subscription.received[0]))}</Typography></Grid>
                            <Grid item xs={2}><DangerButton onClick={() => { if (props.subscription != null) { removeIssue(MakeDateString(new Date(props.subscription.received[0]))); } }} variant='contained'><FontAwesomeIcon icon={faTrash} /></DangerButton></Grid>
                        </Grid> : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.cancel(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faWindowClose} /></Button>
                </DialogActions>
            </Dialog>
        );
    }
    else {
        return null;
    }
}