import React, { useEffect, useContext, useState, useReducer, Reducer, Fragment } from 'react';
import { Grid, TextField, Button, Typography, ButtonGroup } from '@material-ui/core';
import { IUrl } from '../../application/models';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

interface IContactUrlProps {
    currenturls: Array<IUrl>,
    updateUrls: (Urls: Array<IUrl>) => void
}

interface IContactUrlState {
    newlabel: string;
    newurl: string;
    urls: Array<IUrl>;
}


interface IViewUrlProps {
    url: IUrl;
    deleteUrl: (url: IUrl) => void;
    changeUrl: (oldurl: IUrl, newurl: IUrl) => void;
}

interface IViewUrlState {
    editMode: boolean;
}

interface IEditUrlProps {
    urlVars: IUrl;
    commitEdit: (newUrl: IUrl) => void;
    cancelEdit: () => void;
}


export const EditUrl: React.FC<IEditUrlProps> = (props, context) => {
    let [urlState, setUrlState] = useState<IUrl>({ ...props.urlVars });
    return (
        <Grid container xs={12}>
            <Grid item container xs={12} sm={10}>
                <Grid item xs={12} md={3}>
                    <TextField fullWidth value={urlState.label} variant='outlined' label='Label' size='small' onChange={(e) => { setUrlState({ ...urlState, label: e.target.value }); }} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <TextField fullWidth value={urlState.url} variant='outlined' label='Url' size='small' onChange={(e) => { setUrlState({ ...urlState, url: e.target.value }); }} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
                <ButtonGroup fullWidth><Button variant='outlined' onClick={() => { props.commitEdit(urlState); }}><SaveIcon /></Button><Button variant='outlined' onClick={props.cancelEdit}><CancelIcon /></Button></ButtonGroup>
            </Grid>
        </Grid>
    );
}

export const ViewUrl: React.FC<IViewUrlProps> = (props, context) => {
    let e: IUrl = props.url;
    let [urlState, setUrlState] = useState<IViewUrlState>({ editMode: false });

    const cancelEdit: () => void = () => {
        setUrlState({ ...urlState, editMode: false });
    }

    if (urlState.editMode == false) {
        return (
            <Grid container>
                <Grid item xs={12} md={3}><Typography align="left">{e.label}</Typography></Grid>
                <Grid item xs={12} md={7}><Typography align="left">{e.url}</Typography></Grid>
                <Grid item xs={12} md={2}><ButtonGroup fullWidth><Button onClick={() => { setUrlState({ ...urlState, editMode: true }); }}><EditIcon /></Button><Button><DeleteIcon onClick={() => { props.deleteUrl(e); }} /></Button></ButtonGroup></Grid>
            </Grid>
        );
    }
    else {
        return <EditUrl urlVars={e} commitEdit={(n) => { props.changeUrl(e, n); setUrlState({ ...urlState, editMode: false }); }} cancelEdit={cancelEdit} />
    }
}

export const UrlEditor: React.FC<IContactUrlProps> = (props, context) => {
    let [state, setState] = useState<IContactUrlState>({ newlabel: "", newurl: "", urls: props.currenturls });
    
    useEffect(() => {
        setState({ ...state, urls: props.currenturls });
    }, [props.currenturls]);

    const addUrl: () => void = () => {
        let newItem: IUrl = {
            id: "",
            type: "other",
            label: state.newlabel,
            url: state.newurl
        }
        props.updateUrls([...state.urls, newItem]);
        setState({ ...state, newlabel: "", newurl: "" });
    }

    const deleteUrl: (u: IUrl) => void = (u) => {
        let ix: number = state.urls.indexOf(u);
        if (ix != -1) {
            let urls: Array<IUrl> = state.urls.filter((ur) => { return ur != u; });
            props.updateUrls(urls);
            //setState({ ...state, urls: urls });
        }
    }

    const updateUrl: (oldUrl: IUrl, newUrl: IUrl) => void = (o, n) => {
        let urls = [...state.urls];
        let ix: number = urls.indexOf(o);
        urls[ix] = n;
        props.updateUrls(urls);
        //setState({ ...state, urls: urls });
    }

    const changeLabel: (event: any) => void = (e) => {
        setState({ ...state, newlabel: e.target.value });
    }

    const changeUrl: (event: any) => void = (e) => {
        setState({ ...state, newurl: e.target.value });
    }

    return (
        <Fragment>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} md={3}>
                    <TextField fullWidth value={state.newlabel} variant='outlined' label='Label' size='small' onChange={changeLabel} />
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField fullWidth value={state.newurl} variant='outlined' label='URL' size='small' onChange={changeUrl} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <ButtonGroup fullWidth><Button variant='outlined' onClick={addUrl}><AddIcon /></Button></ButtonGroup>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1}>
                {state.urls.map((e, i) => {
                    return (
                        <ViewUrl url={e} changeUrl={updateUrl} deleteUrl={deleteUrl} />
                    );
                })}
            </Grid>
        </Fragment>
    );
}