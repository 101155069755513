import React, { useContext, useState, useEffect } from 'react';
import { IServiceUser } from '../../application/models';
//import { RestApiRepository } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
//import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';

import { Grid, List, ListItem, Paper, Typography, Button, makeStyles, createStyles, Theme, Tooltip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';


export interface IUserListProps {
    resetPassword: (usr: IServiceUser) => void;
    editUser: (usr: IServiceUser) => void;
    removeUser: (usr: IServiceUser) => void;
    isLoading: boolean;
    entries: Array<IServiceUser>;
    isSaving: boolean;
}

export interface IUserListState {
}

export const UserList: React.FC<IUserListProps> = (props, context) => {

    const classes = useStyles();
        
    const groupList: (user: IServiceUser) => string = (user) => {
        let strs: Array <string> =[];
        let outstr = "";
        if (user.superuser) strs.push("Super User");
        if (user.directory) strs.push("Directory");
        if (user.maillog) strs.push("Mail Log");
        if (user.users) strs.push("User Admin");
        outstr = strs.join(", ");
        return outstr;
    }

    return (        
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow className={classes.tableheader}>
                        <TableCell className={classes.tableheadercell}>Username</TableCell>
                        <TableCell className={classes.tableheadercell}>Privileges</TableCell>
                        <TableCell className={classes.tableheadercell}>Admin</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.entries.map((v, i) => {
                        return (<TableRow key={"tr" + i.toString()}>
                            <TableCell><Typography>{v.username}</Typography></TableCell>
                            <TableCell><Typography>{groupList(v)}</Typography></TableCell>
                            <TableCell className={classes.buttoncell}><ButtonGroup><Tooltip title="Edit User" placement="left"><Button variant='contained' color='primary' onClick={() => { props.editUser(v); }}><FontAwesomeIcon icon={faEdit} /></Button></Tooltip><Tooltip title="Change Password" placement="left"><Button variant='contained' onClick={() => { props.resetPassword(v); }}><FontAwesomeIcon icon={faCog} /></Button></Tooltip><Tooltip title="Delete User" placement="right"><Button variant='contained' onClick={() => { props.removeUser(v); }}><FontAwesomeIcon icon={faTrash} /></Button></Tooltip></ButtonGroup></TableCell>
                        </TableRow>);
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButtonContainer: {
            textAlign: "center"
        },
        listitem: {
            margin: '5px'
        },
        tableheader: {
            backgroundColor: "#888888"
        },
        tableheadercell: {
            color: "#ffffff",
            fontSize: "1.2em",
            textAlign: "center"
        },
        buttoncell: {
            textAlign: "center"
        }
    }));