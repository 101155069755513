import React, { Component } from 'react';
import { Typography, Tooltip, makeStyles, createStyles, Theme, ButtonGroup, Button } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { ISubscription } from '../../application/models';
import { MakeDateString, MakeMonthYearString, MakeShortMonthYearString } from '../../application/helperfuncs';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInbox, faEdit } from '@fortawesome/free-solid-svg-icons';

import { DangerButton } from '../controls/buttons';


export class ReceivedCellRenderer extends Component<ICellRendererParams, Array<string>> {
    constructor(props: ICellRendererParams) {
        super(props);
        if (this.props == null || this.props.value == null) {
            this.state = [];
        }
        else {
            this.state = this.props.value.slice(0, 10);
        }
    }
    
    render() {
        if (this.state.length > 0) {
            let last: Date = new Date(this.state[0]);
            if (this.state.length > 1) {
                return (
                    <Tooltip title={
                        <React.Fragment>
                            <span>Previous Received Dates:</span>
                            {this.state.slice(1).map((v, i) => {
                                return (
                                    <React.Fragment><br /><span>{MakeDateString(new Date(v))}</span></React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    } placement="right"><span>{MakeDateString(last)}</span></Tooltip>
                );
            }
            else {
                return (<span>{MakeDateString(last)}</span>);
            }
        }
        return "";
    }
}

export class NextExpectedRenderer extends Component<ICellRendererParams, ISubscription> {
    constructor(props: ICellRendererParams) {
        super(props);

        this.state = this.props.data;
    }

    render() {
        if (this.state.received.length > 0) {
            let cycle: string = this.state.cycle;
            let last: Date = new Date(this.state.received[0]);
            let next: Date = new Date(this.state.received[0]);
            switch (cycle) {
                case "Bi-monthly":
                    next = addMonths(last, 2);
                    break;
                case "Monthly":
                    next = addMonths(last, 1);
                    break;
                case "Quarterly":
                    next = addMonths(last, 3);
                    break;
                case "Weekly":
                    next = addDays(last, 7);
                    break;
                case "Bi-weekly":
                    next = addDays(last, 14);
                    break;
                default:
                    break;
            }
            let today: Date = new Date();
            let overdue: boolean = next < today;
            return (
                <React.Fragment>
                    {
                        overdue ?
                            (<span style={{ color: "red" }}>{MakeShortMonthYearString(next, true)}</span>)
                            : (<span>{MakeShortMonthYearString(next, true)}</span>)}
                </React.Fragment>
                );
        }
        return "";
    }
}

export interface IGeneralCellRendererParams extends ICellRendererParams {
    emphasisauto: string[];
}

export class BoolCellRenderer extends Component<IGeneralCellRendererParams, ISubscription> {
    constructor(props: IGeneralCellRendererParams) {
        super(props);

        this.state = this.props.data;
    }

    render() {
        let emphasize: boolean = this.props.emphasisauto.indexOf(this.state.auto) > -1;
        let text: string = this.props.value ? "Yes" : "";

        return (
            <React.Fragment>
                {
                    emphasize ?
                        (<span style={{ fontWeight: "bold" }}>{text}</span>)
                        : (<span>{text}</span>)}
            </React.Fragment>
        );
    }
}

export class FieldCellRenderer extends Component<IGeneralCellRendererParams, ISubscription> {
    constructor(props: IGeneralCellRendererParams) {
        super(props);

        this.state = this.props.data;
    }

    render() {
        let emphasize: boolean = this.props.emphasisauto.indexOf(this.state.auto) > -1;
        
        return (
            <React.Fragment>
                {
                    emphasize ?
                        (<span style={{ fontWeight: "bold" }}>{this.props.value}</span>)
                        : (<span>{this.props.value}</span>)}
            </React.Fragment>
        );
    }
}

export interface IActionsCellRendererParams extends ICellRendererParams {
    receiveItem: (data: ISubscription) => void;
    editItem: (data: ISubscription) => void;
    deactivateItem: (data: ISubscription) => void;
}

export class PeriodicalActionsRenderer extends Component<IActionsCellRendererParams, ISubscription> {
    constructor(props: IActionsCellRendererParams) {
        super(props);

        this.state = this.props.data;
    }

    getReactContainerClasses() {
        return ['subscription-toolbar'];
    }


    getReactContainerStyle() {
        return {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center'
        };
    }

    render() {
        return (
            <div className="gridbuttonbar">
                <button title="Receive Issue" className="gridiconbutton" onClick={() => { this.props.receiveItem(this.state); }}><FontAwesomeIcon icon={faInbox} size='xs' /></button>
                <button title="Edit Subscription" className="gridiconbutton caution" onClick={() => { this.props.editItem(this.state); }}><FontAwesomeIcon icon={faEdit} size='xs' /></button>
                <button title="Delete Subscription" className="gridiconbutton danger" onClick={() => { this.props.deactivateItem(this.state); }}><FontAwesomeIcon icon={faTrash} size='xs' /></button>
            </div>
            );
    }
}