import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, Typography, DialogContentText, DialogContent, DialogActions, TextField, Button, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
//import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ISubscription } from '../../application/models';
import { MakeDateString } from '../../application/helperfuncs';
import DateFnsUtils from '@date-io/date-fns';

import { DangerButton, SafeButton } from '../controls/buttons';


export interface IReceiveIssueProps {
    subscription: ISubscription | null;
    open: boolean;
    save: (id: number, date: string) => void;
    cancel: () => void;
}

interface FormState {
    receiveddate: Date | null;
    subid: number;
}

const getToday: () => Date = () => {
    let outdate: Date = new Date();
    outdate.setHours(0, 0, 0, 0);
    return outdate;
}

export const ReceiveIssueDialog: React.FC<IReceiveIssueProps> = (props, context) => {
    const [formState, changeFormState] = useState<FormState>({
        receiveddate: getToday(),
        subid: 0
    });

    useEffect(() => {
        if (props.subscription == null) {
            changeFormState({ ...formState, subid: 0, receiveddate: getToday() });
        }
        else {
            changeFormState({ ...formState, subid: props.subscription.id, receiveddate: getToday() });
        }
    }, [props.subscription]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Dialog open={props.open}>
                <DialogTitle><Typography>{"Receive " + (props.subscription != null ? props.subscription.name : "")}</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please select the date the issue was received.
                    </DialogContentText>
                    <Grid item container xs={12} justifyContent='center' alignItems='center'>
                        <Grid item xs={12} md={5}>
                            <Typography>Date Received</Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <KeyboardDatePicker fullWidth inputVariant="outlined" value={formState.receiveddate} onChange={(d: Date | null) => { changeFormState({ ...formState, receiveddate: d }); }} format='yyyy-MM-dd' />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SafeButton onClick={() => { if (formState.receiveddate != null) { props.save(formState.subid, MakeDateString(formState.receiveddate)); } }} variant="contained"><FontAwesomeIcon icon={faSave} /></SafeButton>
                    <DangerButton onClick={() => { props.cancel(); }} variant="contained"><FontAwesomeIcon icon={faWindowClose} /></DangerButton>
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
}