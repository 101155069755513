import React, { useState, useContext } from 'react';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, Button, Tooltip, Box, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MailLogList } from './list';

import { EditMailLog } from './edit';
import { IMailLog, GetDefaultMailLog } from '../../application/models';
import { Reports } from '../reports/main';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DeactivateCorrespondents } from './deactivatecorrespondent';


interface IMailLogProps {
}

interface IMailLogState {
    selectedStart: Date | null,
    selectedEnd: Date | null,
    mode: "list" | "edit" | "senders",
    activeItem: IMailLog | null
}

function GetDefaultMailLogState(): IMailLogState { 
    let current: Date = new Date();
    let first: number = current.getDate() - current.getDay();
    let firstday: Date = new Date(current.setDate(first));
    let lastday: Date = new Date(current.setDate(current.getDate() + 6));
    return {
        selectedStart: firstday,
        selectedEnd: lastday,
        mode: "list",
        activeItem: null
    }
}

export const MailLog: React.FC<IMailLogProps> = (props, context) => {
    let [state, setState] = useState<IMailLogState>(GetDefaultMailLogState());
    let [isLoading, setIsLoading] = useState<boolean>(false);
    const { DeleteMailItem } = useFieldsteadApi();
    //const api = useContext(ApiContext);

    const changeStart: (date: Date | null) => void = (d) => {
        setState({ ...state, selectedStart: d });
    }

    const changeEnd: (date: Date | null) => void = (d) => {
        setState({ ...state, selectedEnd: d });
    }

    const prevWeek: () => void = () => {
        let base: Date = state.selectedStart || new Date();
        let newEnd: Date = new Date(base.setDate(base.getDate() - 1));
        let newStart: Date = new Date(base.setDate(base.getDate() - 6));
        setState({ ...state, selectedEnd: newEnd, selectedStart: newStart });
    }

    // This feels EXTREMELY hacky, but is a result of fluent style.  newStart calculation works off of a modified base, as base changes by using "setDate"
    const nextWeek: () => void = () => {
        let base: Date = state.selectedEnd || new Date();
        let newEnd: Date = new Date(base.setDate(base.getDate() + 7));
        let newStart: Date = new Date(base.setDate(base.getDate() - 6));
        setState({ ...state, selectedEnd: newEnd, selectedStart: newStart });
    }

    const loadingComplete: () => void = () => {
        setIsLoading(false);
    }

    const loadingStarted: () => void = () => {
        setIsLoading(true);
    }

    const editItem: (item: IMailLog) => void = (item) => {
        setState({ ...state, mode: "edit", activeItem: item });
    }

    const deleteItem: (id: number | null) => void = (id) => {
        if (id != null) {
            DeleteMailItem(id).then((response) => { setState({ ...state, mode: "list" }) });
        }
    }

    const editComplete: () => void = () => {
        setState({ ...state, mode: "list", activeItem: null });
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container alignItems='flex-start' justifyContent='space-around'>
                {state.mode == "senders" ?
                    <Grid container item xs={10} alignItems='center' justify='space-around' spacing={1}>
                        <DeactivateCorrespondents closeForm={() => { setState({ ...state, mode: "list" }); }} />
                    </Grid> :
                    state.mode == "list" || state.activeItem == null ?
                    <Grid container item xs={10} alignItems='center' justifyContent='space-around' spacing={1}>

                        <Grid item xs={1}>
                            <Tooltip title="Previous Week"><Button color="primary" variant="contained" disabled={isLoading} onClick={prevWeek}><FontAwesomeIcon icon={faChevronLeft} /> &nbsp;Previous</Button></Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="Next Week"><Button color="primary" variant="contained" disabled={isLoading} onClick={nextWeek}>Next&nbsp;<FontAwesomeIcon icon={faChevronRight} /></Button></Tooltip>
                        </Grid>
                        <Grid item xs={5} md={3}>
                            <KeyboardDatePicker fullWidth inputVariant="outlined" value={state.selectedStart} maxDate={state.selectedEnd} label="Start" onChange={changeStart} format='MMM dd, yyyy' />
                        </Grid>
                        <Grid item xs={5} md={3}>
                            <KeyboardDatePicker fullWidth inputVariant="outlined" value={state.selectedEnd} label="End" minDate={state.selectedStart} onChange={changeEnd} format='MMM dd, yyyy' />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <MailLogList startdate={state.selectedStart} enddate={state.selectedEnd} updateEntry={editItem} deleteEntry={deleteItem} setLoading={loadingStarted} endLoading={loadingComplete} />
                        </Grid>
                    </Grid>
                    :
                    <Grid container item xs={10} alignItems='center' justifyContent='space-around' spacing={1}>
                        <Grid item xs={12}>
                            <EditMailLog mailitem={state.activeItem} callback={editComplete} />
                        </Grid>
                    </Grid>}
                <Grid item container xs={2} alignItems="stretch" spacing={2}>
                    <Grid item xs={12}>
                        <Button color="primary" fullWidth onClick={() => { editItem(GetDefaultMailLog()); }} variant='contained' startIcon={<FontAwesomeIcon size='xs' icon={faPlus} />} ><Box fontSize='.7em'>Add Mail</Box></Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Reports baseReportEnd={state.selectedEnd} baseReportStart={state.selectedStart} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" fullWidth onClick={() => { setState({...state, mode: "senders"}); }} variant='contained' startIcon={<FontAwesomeIcon size='xs' icon={ faUsers } /> }><Box fontSize='.7em'>Manage Senders</Box></Button>
                    </Grid>
                </Grid>
            </Grid>

        </MuiPickersUtilsProvider>
    );
}