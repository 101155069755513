import { Button, Grid, Select, MenuItem, TextField, makeStyles, createStyles, Theme, withStyles } from '@material-ui/core';

export const DangerButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.danger.contrastText,
        backgroundColor: theme.palette.danger.main,
        '&:hover': {
            backgroundColor: theme.palette.danger.dark
        }
    }
}))(Button);

export const SafeButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.safe.contrastText,
        backgroundColor: theme.palette.safe.main,
        '&:hover': {
            backgroundColor: theme.palette.safe.dark
        }
    }
}))(Button);