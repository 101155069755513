import React, { useContext, useState, useEffect } from 'react';
import { IServiceUser } from '../../application/models';

//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
//import { RestApiRepository } from '../../application/restapirepository';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, DialogContentText, TextField, Grid, Button, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons';


interface IRemoveUserProps {
    user: IServiceUser | null,
    cancel: () => void,
    save: (res: RemoveResult) => void,
    open: boolean
}

export interface RemoveResult {
    success: boolean,
    message?: string
}


export const RemoveUserForm: React.FC<IRemoveUserProps> = (props, context) => {
    //let api: RestApiRepository = useContext(ApiContext);
    const { RemoveUser } = useFieldsteadApi();

    const removeUser: () => void = () => {
        if (props.user != null) {
            RemoveUser(props.user).then((response) => { props.save(response); });
        }
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle><Typography>Edit Privileges</Typography></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Delete user: {props.user != null ? props.user.username : "N/A"}?
                </DialogContentText>                
            </DialogContent>
            <DialogActions>
                <Button onClick={removeUser} color="primary" variant="contained"><FontAwesomeIcon icon={faCheck} /></Button>
                <Button onClick={() => { props.cancel(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faWindowClose} /></Button>
            </DialogActions>
        </Dialog>
    );
}

