import React, { useContext, useState, useEffect } from 'react';
import { IServiceUser } from '../../application/models';

//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
//import { RestApiRepository } from '../../application/restapirepository';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, DialogContentText, TextField, Grid, Button, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';

interface IEditUserProps {
    user: IServiceUser | null,
    cancel: () => void,
    save: (res: EditResult) => void,
    open: boolean
}

export interface EditResult {
    success: boolean;
    message: string;
}

interface EditState {
    id: number;
    username: string;
    superuser: boolean;
    directory: boolean;
    maillog: boolean;
    users: boolean;    
}

export const EditUserForm: React.FC<IEditUserProps> = (props, context) => {
    const [formState, setFormState] = useState<EditState>({ id: 0, username: "", superuser: false, directory: false, maillog: false, users: false });
    const [activeState, setActiveState] = useState<boolean>(true);
    //let api: RestApiRepository = useContext(ApiContext);
    const { SaveUser } = useFieldsteadApi();

    const savePrivs: () => void = () => {
        setActiveState(false);
        let result: EditResult = { success: true, message: "Placeholder" };
        let saveUser: IServiceUser = { ...formState };
        SaveUser(saveUser).then((res) => {
            if (res.success) {
                props.save({ success: true, message: "Saved user" });
            }
            else {
                props.cancel();
            }
        });

        //props.save(result);
    }

    useEffect(() => {
        setActiveState(true);
        if (props.user == null) {
            setFormState({ id: 0, username: "", superuser: false, directory: false, maillog: false, users: false });
        }
        else {
            setFormState({ ...formState, id: props.user.id, username: props.user.username, superuser: props.user.superuser, users: props.user.users, maillog: props.user.maillog, directory: props.user.directory });
        }
    }, [props.user]);

    const toggle: (priv: string) => void = (priv) => {
        switch (priv) {
            case "superuser":
                setFormState({ ...formState, superuser: !formState.superuser });
                return;
            case "directory":
                setFormState({ ...formState, directory: !formState.directory });
                return;
            case "users":
                setFormState({ ...formState, users: !formState.users });
                return;
            case "maillog":
                setFormState({ ...formState, maillog: !formState.maillog });
                return;
            default:
                return;
        }
    }

    const setName: (val: string) => void = (val) => {
        setFormState({ ...formState, username: val });
    }

    return (<Dialog open={props.open}>
        <DialogTitle><Typography>Edit Privileges</Typography></DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please enter user information.{formState.id == 0 ? "  If this is a user outside of the @nonnobis.com domain, please use a full e-mail address for the username." : ""}
            </DialogContentText>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {formState.id == 0 ?
                        <TextField value={formState.username} variant="outlined" size="small" fullWidth label="User Name" onChange={(e) => { setName(e.target.value); }} /> :
                        <Typography variant="h5">{formState.username}</Typography>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={formState.superuser} onChange={() => { toggle("superuser");}} name="superuser" />}
                        label="Super User"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={formState.maillog} onChange={() => { toggle("maillog"); }} name="maillog" />}
                        label="Log"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={formState.directory} onChange={() => { toggle("directory"); }} name="directory" />}
                        label="Directory"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={formState.users} onChange={() => { toggle("users"); }} name="users" />}
                        label="Users"
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button disabled={ !activeState } onClick={() => { savePrivs(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faSave} /></Button>
            <Button disabled={ !activeState } onClick={() => { props.cancel(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faWindowClose} /></Button>
        </DialogActions>
    </Dialog>);
}