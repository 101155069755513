import React, { useState, useContext, Fragment } from 'react';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
//import { AuthContext, AuthenticationInfo } from '../../contexts/authcontext';
//import { RestApiRepository } from '../../application/restapirepository';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, Button, Tooltip, Box, Typography, TextField } from '@material-ui/core';
//import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { DocumentDialog } from './request';
import { IDocumentRequest } from '../../application/models';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPrint } from '@fortawesome/free-solid-svg-icons';


export interface IReportsProps {
    baseReportStart: Date | null;
    baseReportEnd: Date | null;
}

interface IReportsState {
    isOpen: boolean;
}

function showFile(filename: string, blob: Blob | null): void {
    if (blob != null) {
        var newBlob = new Blob([blob], { type: "text/html" });

        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //    window.navigator.msSaveOrOpenBlob(newBlob);
        //    return;
        //}

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    } 
}

export const Reports: React.FC<IReportsProps> = (props, context) => {
    const [state, setState] = useState<IReportsState>({ isOpen: false });
    const { RequestDocument } = useFieldsteadApi();
    //const api: RestApiRepository = useContext(ApiContext);

    const activateRequest: () => void = () => {

    }

    const displayDocument: (result: { success: boolean, filename: string, file: Blob | null }) => void = (result) => {
        showFile(result.filename, result.file);
        setState({...state, isOpen: false });
    }

    const requestReport: (req: IDocumentRequest) => void = (req) => {
        RequestDocument(req).then((response) => { displayDocument(response); });
    }

    const cancelRequest: () => void = () => {
        setState({ ...state, isOpen: false });
    }

    return (
        <Fragment>
            <DocumentDialog open={state.isOpen}
                request={requestReport}
                cancel={cancelRequest}
                baseReportStart={props.baseReportStart}
                baseReportEnd={props.baseReportEnd} />
            <Button fullWidth onClick={() => { setState({ ...state, isOpen: true }); }} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faPrint} size='xs' />}><Box fontSize='.7em'>Generate Documents</Box></Button>
        </Fragment>
    );
}