import React, { useContext, useState, useEffect } from 'react';
import { IServiceUser } from '../../application/models';
//import { AuthenticationInfo } from '../../contexts/authcontext';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
//import { RestApiRepository } from '../../application/restapirepository';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, DialogContentText, TextField, Grid, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';

interface IResetProps {
    user: IServiceUser | null;
    open: boolean;
    save: (result: PasswordResult) => void;
    cancel: () => void;
}

export interface PasswordResult {
    success: boolean;
    message?: string;
}

interface ResetState {
    newpassword: string;
    confirmpassword: string;
    submitting: boolean;
}

export const ResetPasswordForm: React.FC<IResetProps> = (props, context) => {
    let { GetUserInfo, ResetPassword } = useFieldsteadApi();
    //let auth: AuthenticationInfo | null = GetUserInfo();
    let [formstate, setFormstate] = useState<ResetState>({ newpassword: "", confirmpassword: "", submitting: false });
    //let api: RestApiRepository = useContext(ApiContext);

    const setPassword: () => void = () => {
        if (formstate.newpassword == formstate.confirmpassword && props.user != null) {
            ResetPassword(props.user, formstate.newpassword).then((res) => { props.save(res); });
            setFormstate({ ...formstate, submitting: true });
        }
    }

    useEffect(() => {
        setFormstate({ newpassword: "", confirmpassword: "", submitting: false });
    }, [props.user]);

    return (<Dialog open={props.open}>
        <DialogTitle><Typography>Update Password</Typography></DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please enter new password, and confirm, in order to reset password for {props.user != null ? props.user.username : "UNKNOWN USER"}.
            </DialogContentText>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField fullWidth security="*" value={formstate.newpassword} size="small" type="password" label="New Password" variant='outlined' onChange={(e) => { setFormstate({ ...formstate, newpassword: e.target.value }); }} error={formstate.newpassword != formstate.confirmpassword} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth security="*" value={formstate.confirmpassword} size="small" type="password" label="Confirm Password" variant='outlined' onChange={(e) => { setFormstate({ ...formstate, confirmpassword: e.target.value }); }} error={formstate.newpassword != formstate.confirmpassword} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { setPassword(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faSave} /></Button>
            <Button onClick={() => { props.cancel(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faWindowClose} /></Button>
        </DialogActions>
    </Dialog>);
}