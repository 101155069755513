import React, { useEffect, useContext, useState, useReducer, Reducer, Fragment } from 'react';
import { Grid, TextField, Button, Typography, ButtonGroup } from '@material-ui/core';
import { IPhone } from '../../application/models';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

interface IContactPhoneNumbersProps {
    currentphonenumbers: Array<IPhone>,
    updatePhoneNumbers: (phonenumbers: Array<IPhone>) => void
}

interface IContactPhoneNumbersState {
    newlabel: string;
    newphone: string;
    phonenumbers: Array<IPhone>;
}

interface IViewPhoneNumberProps {
    phone: IPhone;
    deletePhone: (phonenumber: IPhone) => void;
    changePhone: (oldphone: IPhone, newphone: IPhone) => void;
}

interface IViewPhoneState {
    editMode: boolean;
}

interface IEditPhoneProps {
    phoneVars: IPhone;
    commitEdit: (newPhone: IPhone) => void;
    cancelEdit: () => void;
}


export const EditPhone: React.FC<IEditPhoneProps> = (props, context) => {
    let [phoneState, setPhoneState] = useState<IPhone>({ ...props.phoneVars });
    return (
        <Grid container xs={12}>
            <Grid item container xs={12} sm={10}>
                <Grid item xs={12} md={3}>
                    <TextField fullWidth value={phoneState.label} variant='outlined' label='Label' size='small' onChange={(e) => { setPhoneState({ ...phoneState, label: e.target.value }); }} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <TextField fullWidth value={phoneState.phonenumber} variant='outlined' label='Phone Number' size='small' onChange={(e) => { setPhoneState({ ...phoneState, phonenumber: e.target.value }); }} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
                <ButtonGroup fullWidth><Button variant='outlined' onClick={() => { props.commitEdit(phoneState); }}><SaveIcon /></Button><Button variant='outlined' onClick={props.cancelEdit}><CancelIcon /></Button></ButtonGroup>
            </Grid>
        </Grid>
    );
}

export const ViewPhone: React.FC<IViewPhoneNumberProps> = (props, context) => {
    let e: IPhone = props.phone;
    let [phoneState, setPhoneState] = useState<IViewPhoneState>({ editMode: false });

    const cancelEdit: () => void = () => {
        setPhoneState({ ...phoneState, editMode: false });
    }

    if (phoneState.editMode == false) {
        return (
            <Grid container>
                <Grid item xs={12} md={3}><Typography align="left">{e.label}</Typography></Grid>
                <Grid item xs={12} md={7}><a href={"tel:" + e.phonenumber}><Typography align="left">{e.phonenumber}</Typography></a></Grid>
                <Grid item xs={12} md={2}><ButtonGroup fullWidth><Button onClick={() => { setPhoneState({ ...phoneState, editMode: true }); }}><EditIcon /></Button><Button><DeleteIcon onClick={() => { props.deletePhone(e); }} /></Button></ButtonGroup></Grid>
            </Grid>
        );
    }
    else {
        return <EditPhone phoneVars={e} commitEdit={(n) => { props.changePhone(e, n); setPhoneState({ ...phoneState, editMode: false }); }} cancelEdit={cancelEdit} />
    }
}


export const PhoneEditor: React.FC<IContactPhoneNumbersProps> = (props, context) => {
    let [state, setState] = useState<IContactPhoneNumbersState>({ newlabel: "", newphone: "", phonenumbers: props.currentphonenumbers });

    useEffect(() => {
        setState({ ...state, phonenumbers: props.currentphonenumbers });
    }, [props.currentphonenumbers]);

    const addPhone: () => void = () => {
        let newItem: IPhone = {
            id: "",
            type: "other",
            label: state.newlabel,
            phonenumber: state.newphone
        }
        props.updatePhoneNumbers([...state.phonenumbers, newItem]);
        setState({ ...state, newlabel: "", newphone: "" });
    }

    const deletePhone: (p: IPhone) => void = (p) => {
        let ix: number = state.phonenumbers.indexOf(p);
        if (ix != -1) {
            let phonenumbers: Array<IPhone> = state.phonenumbers.filter((pn) => { return pn != p; });
            props.updatePhoneNumbers(phonenumbers);
        //    setState({ ...state, phonenumbers: phonenumbers });
        }
    }

    const changePhone: (oldPhone: IPhone, newPhone: IPhone) => void = (o, n) => {
        let phonenumbers = [...state.phonenumbers];
        let ix: number = phonenumbers.indexOf(o);
        phonenumbers[ix] = n;
        props.updatePhoneNumbers(phonenumbers);
        //setState({ ...state, phonenumbers: phonenumbers });
    }

    const changeLabel: (event: any) => void = (e) => {
        setState({ ...state, newlabel: e.target.value });
    }

    const changePhoneNumber: (event: any) => void = (e) => {
        setState({ ...state, newphone: e.target.value });
    }

    return (
        <Fragment>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} md={3}>
                    <TextField fullWidth value={state.newlabel} variant='outlined' label='Label' size='small' onChange={changeLabel} />
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField fullWidth value={state.newphone} variant='outlined' label='Phone Number' size='small' onChange={changePhoneNumber} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <ButtonGroup fullWidth><Button variant='outlined' onClick={addPhone}><AddIcon /></Button></ButtonGroup>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1}>
                {state.phonenumbers.map((p, i) => {
                    return (
                        <ViewPhone phone={p} deletePhone={deletePhone} changePhone={changePhone} key={'phned' + i.toString()} />
                    );
                })}
            </Grid>
        </Fragment>
        );
}