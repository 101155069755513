import React from 'react';
import { IAddress } from "../../application/models";
import { Grid, Typography, Button, makeStyles, Theme, createStyles } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';

interface IAddressItem {
    address: IAddress;
    index: number;
}

export const AddressDetails: React.FC<IAddressItem> = (props, context) => {
    let a: IAddress = props.address;
    const classes = useStyles();

    const mapGoogle: () => void = () => {
        let url: string = "https://www.google.com/maps/place/" + mapaddress(props.address);
        
        var link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        link.click();
    }

    const mapaddress: (addr: IAddress) => string = (addr) => {
        let streetlines: string = addr.streetAddress.replace('\n', ',').replace('\r', '').replace(' ', '+');
        return streetlines + ',' + addr.city + ',' + addr.state + '+' + addr.zipcode + ', ' + addr.country;
    }

    return (
        <Grid className={classes.spacedline} item container xs={12} key={a.id}>
            <Grid item container xs={11}>
            {a.label != null && a.label != "" ? <Grid item xs={12}><Typography align="left">{a.label}:</Typography></Grid> : null}
                <Grid item xs={12}>{a.streetAddress.split('\n').map((v, i) => {
                    return (<Typography align="left" key={'addrl' + i.toString()}><span dangerouslySetInnerHTML={{ __html: v }} /></Typography>) })}
                    <Typography align="left"><span dangerouslySetInnerHTML={{ __html: a.city }} />, <span dangerouslySetInnerHTML={{ __html: a.state }} />  {a.zipcode}</Typography>
                <Typography align="left">{a.country}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Button onClick={mapGoogle} color='primary' variant='contained'><FontAwesomeIcon icon={faMap} /></Button>
            </Grid>
        </Grid>
    );
}



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacedline: {
            paddingTop: '8px',
            paddingBottom: '8px'
        }
    }));