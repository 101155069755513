import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ITag } from '../../application/models';
import { TagChip } from './tagchip';

interface IViewTagProps {
    tag: ITag;
    edit: (t: ITag) => void;
}

export const ViewTag: React.FC<IViewTagProps> = (props, context) => {

    return (
        <Grid container>
            <Grid item xs={4}>
                <TagChip tag={props.tag} onclick={null} ondelete={null} />
            </Grid>
            <Grid item xs={4}>
                <Typography>{props.tag.name}</Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography>{props.tag.priority}</Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography>{props.tag.active.toString()}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Button variant='outlined' color='primary' onClick={() => props.edit(props.tag)}><FontAwesomeIcon icon={faEdit} /></Button>
            </Grid>
        </Grid>
    );
}