import { createContext } from 'react';

export class AuthenticationInfo {
    isAuthenticated: boolean;
    token: string;
    tokenobj: any;
    groups: Array<string>;

    constructor(token: string) {
        this.tokenobj = this.parseJwt(token);
        this.groups = this.tokenobj["cognito:groups"] ?? [];
        this.token = token;
        this.isAuthenticated = true;
    }

    //setToken(token: string, callback: undefined | ((auth: AuthenticationInfo) => void)): void {
    //    this.tokenobj = this.parseJwt(token);
    //    this.groups = this.tokenobj["cognito:groups"] ?? [];
    //    this.token = token;
    //    this.isAuthenticated = true;
    //    if (callback != null) {
    //        callback(this);
    //    }
    //}

    checkGroups(chk: string): boolean {
        let groups: Array<string> = this.groups ?? [];
        return groups.indexOf("superuser") > -1 || groups.indexOf(chk) > -1;
    }

    parseJwt(jwtoken: string): any {
        var base64Url = jwtoken.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };
}

//export const AuthContext = createContext<AuthenticationInfo>(new AuthenticationInfo());