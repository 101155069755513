// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:0aaf2317-34a4-4d46-b572-3aa034f1ffc1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_GWXDKfH6u",
    "aws_user_pools_web_client_id": "46to8cbr44q1dfjo8todjjmue6"
};


export default awsmobile;
