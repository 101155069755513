import React, { useEffect, useContext, useState, useReducer, Reducer, Fragment } from 'react';
import { IFormData } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
import { IMailLog, GetDefaultMailLog } from '../../application/models';

import { Grid, CircularProgress, Typography, Chip, FormControlLabel, Box, TextField, Select, FormControl, MenuItem, OutlinedInput, Button, ButtonGroup, InputLabel, makeStyles, Theme, createStyles, withStyles, Checkbox } from '@material-ui/core';
import { KeyboardDatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
//import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface IEditMailLogProps {
    mailitem: IMailLog;
    callback: () => void;
}

interface IMailLogState {
    isLoading: boolean;
    mailItem: IMailLog | null;
}

export const EditMailLog: React.FC<IEditMailLogProps> = (props, context) => {
    const { SaveMailLogEntry } = useFieldsteadApi();
    //const api = useContext(ApiContext);
    const [state, setState] = useState<IMailLogState>({ isLoading: false, mailItem: props.mailitem });

    //if (props.mailid == null) {
    //    setState({ ...state, isLoading: false, mailItem: GetDefaultMailLog() });
    //}
    //else {
    //    api.FetchMailLogEntry(props.mailid, (d) => {
    //        setState({ ...state, isLoading: false, mailItem: d });
    //    });
    //}

    const saveData: (data: IMailLog) => void = (data) => {
        SaveMailLogEntry(data).then((resp: string) => { props.callback(); });
    }

    const cancel: () => void = () => {
        props.callback();
    }

    return (
        <Grid container alignItems="center" justifyContent='space-around'>
            {state.isLoading == null || state.mailItem == null ? <Grid item xs={2}><CircularProgress /></Grid> :
                <Grid item xs={10}><EditForm data={state.mailItem} save={saveData} cancel={cancel} /></Grid>
                }
        </Grid>
        );
}

interface IEditFormProps {
    data: IMailLog;
    save: (data: IMailLog) => void;
    cancel: () => void;
}

interface IFormComponents {
    mailcats: Array<string>;
    contactNames: Array<string>;
}

const EditForm: React.FC<IEditFormProps> = (props, context) => {
    const [formState, setFormState] = useState<IMailLog>(props.data == null ? GetDefaultMailLog() : { ...props.data });
    const [formComponents, setFormComponents] = useState<IFormComponents>({ mailcats: [], contactNames: [] })
    const { FetchFormData } = useFieldsteadApi();

    const classes = useStyles();

    //const api: RestApiRepository = useContext(ApiContext);

    const lookupRoute: (folder: string) => string | null = (folder) => {
        switch (folder) {
            case "Red":
                return "RGA";
            case "Orange":
                return "HFA";
            case "Green":
                return "Family/3";
            case "Yellow":
                return "DFGA";
            case "Red & Orange":
                return "HFA/RGA";
            default:
                return formState.routed;
        }
        return "";
    }

    useEffect(() => {
        FetchFormData().then((data: IFormData) => {
            setFormComponents({ ...formComponents, mailcats: data.categories.sort(), contactNames: data.contacts.sort() });
        });
    }, []);

    const setValue: (field: string, value: string) => void = (f, v) => {
        switch (f) {
            case "type":
                if (v == "I" || v == "G" || v == "C" || v == "B" || v == "T" || v == null) {
                    setFormState({ ...formState, type: v });
                }
                return;
            case "category":
                setFormState({ ...formState, category: v });
                return;
            case "comment":
                setFormState({ ...formState, comment: v });
                return;
            case "sentto":
                setFormState({ ...formState, sent: v });
                return;
            case "routed":
                setFormState({ ...formState, routed: v });
                return;
            case "from":
                setFormState({ ...formState, from: v });
                return;
            case "folder":
                let route = lookupRoute(v);
                setFormState({ ...formState, folder: v, routed: route });
                return;
            case "invloc":
                setFormState({ ...formState, invloc: v });
                return;
            case "invaddress":
                setFormState({ ...formState, invaddressstreet: v });
                return;
            case "invcity":
                setFormState({ ...formState, invaddresscity: v });
                return;
            case "invstate":
                setFormState({ ...formState, invaddressstate: v });
                return;
            case "invzip":
                setFormState({ ...formState, invaddresszip: v });
                return;
            case "invrsvp":
                setFormState({ ...formState, invrsvp: v });
                return;
            case "to215":
                setFormState({ ...formState, to215: !(formState.to215 ?? false) });
                return;
            case "timesensitive":
                setFormState({ ...formState, timesensitive: !(formState.timesensitive ?? false) });
                return;
            default:
                return;
        }
    }

    const changeDate: (field: string, date: Date | null) => void = (f, d) => {
        switch (f) {
            case "date":
                setFormState({ ...formState, receiveddate: d });
                return;
            case "invdate":
                let invstart: Date | null = d;
                let invend: Date | null = d;
                if (invstart != null) {
                    if (formState.invstarttime == null) {
                        invstart = null;
                    }
                    else {
                        invstart.setHours(formState.invstarttime.getHours(), formState.invstarttime.getMinutes(), 0, 0);
                    }
                }
                if (invend != null) {
                    if (formState.invendtime == null) {
                        invend = null;
                    }
                    else {
                        invend.setHours(formState.invendtime.getHours(), formState.invendtime.getMinutes(), 0, 0);
                    }
                }

                setFormState({ ...formState, invdate: d, invstarttime: invstart, invendtime: invend });
                return;
            case "invstart":
                if (formState.invdate == null || d == null) {
                    setFormState({ ...formState, invstarttime: null });
                }
                else {
                    setFormState({ ...formState, invstarttime: d });
                }
                return;
            case "invend":
                if (formState.invdate == null || d == null) {
                    setFormState({ ...formState, invendtime: null });
                }
                else {
                    setFormState({ ...formState, invendtime: d });
                }
                return;
            case "invresponsedate":
                if (d == null) {
                    setFormState({ ...formState, invresponsedate: null });
                }
                else {
                    setFormState({ ...formState, invresponsedate: d });
                }
                return;
            default:
                return;
        }
    }

    return (
        <Grid container alignItems="flex-start" justifyContent="space-between" spacing={1}>
            <Grid item container xs={12} alignItems="center">
                <Grid item xs={12} md={3}>
                    <Typography>Mail Type</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Select fullWidth value={formState.type} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("type", e.target.value); }} variant="outlined">
                        <MenuItem value="" disabled>Select...</MenuItem>
                        <MenuItem value="G">Mail</MenuItem>
                        <MenuItem value="I">Invitation</MenuItem>
                        <MenuItem value="T">Gift</MenuItem>
                        <MenuItem value="C">Christmas Card</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid item container xs={12} alignItems="center">
                <Grid item xs={12} md={3}>
                    <Typography>Date Received</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                    <KeyboardDatePicker fullWidth inputVariant="outlined" value={formState.receiveddate} onChange={(d: Date | null) => { changeDate("date", d); }}  format='MMM dd, yyyy' />
                </Grid>
            </Grid>
            {formState.type == "G" ?
                <Fragment>
                    <Grid item container xs={12} md={12} alignItems="center">
                        <Grid item xs={12} md={3}><Typography>Folder</Typography></Grid>
                        <Grid item xs={12} md={9}>
                            <Select fullWidth value={formState.folder} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("folder", e.target.value); }} variant="outlined">
                                <MenuItem value="" disabled>Select...</MenuItem>
                                <MenuItem value="Red & Orange"><Chip className={classes.redorange} label="Red & Orange" /></MenuItem>
                                <MenuItem value="Orange"><Chip className={classes.orange} label="Orange" /></MenuItem>
                                <MenuItem value="Red"><Chip className={classes.red} label="Red" /></MenuItem>
                                <MenuItem value="Pink"><Chip className={classes.pink} label="Pink" /></MenuItem>
                                <MenuItem value="Purple"><Chip className={classes.purple} label="Purple" /></MenuItem>
                                <MenuItem value="Green"><Chip className={classes.green} label="Green" /></MenuItem>
                                <MenuItem value="Yellow"><Chip className={classes.yellow} label="Yellow" /></MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} alignItems="center">
                        <Grid item xs={12} md={3}><Typography>Category</Typography></Grid>
                        <Grid item xs={12} md={9}>
                            <Autocomplete
                                value={formState.category == null ? undefined : formState.category}
                                inputValue={formState.category == null ? undefined : formState.category}
                                onInputChange={(event: object, newInputValue: string) => {
                                    setValue("category", newInputValue);
                                }}
                                id="categorySearch"
                                freeSolo
                                options={formComponents.mailcats}
                                renderInput={(params: any) => (
                                    <TextField {...params} margin="normal" variant="outlined" size="small" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Fragment>
                : null}
            <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={12} md={3}><Typography>From</Typography></Grid>
                <Grid item xs={12} md={9}>
                    <Autocomplete
                        value={formState.from == null ? undefined : formState.from}
                        inputValue={formState.from == null ? undefined : formState.from}
                        onInputChange={(event: object, newInputValue: string) => {
                            setValue("from", newInputValue);
                        }}
                        id="fromSearch"
                        freeSolo
                        options={formComponents.contactNames}
                        renderInput={(params: any) => (
                            <TextField {...params} margin="normal" variant="outlined" size="small" />
                        )}
                    />
                </Grid>
            </Grid>
            {formState.type != "B" && formState.type != "C" ?
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography>Comments/Description</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField multiline rows="4" fullWidth value={formState.comment} variant='outlined' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("comment", e.target.value); }} />
                    </Grid>
                </Grid>
                : null}
            {formState.type == "I" ?
                <Grid item container xs={12}>
                    <Grid xs={12}><Typography>Invitation Date/Time</Typography></Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker fullWidth inputVariant="outlined" value={formState.invdate} onChange={(d: Date | null) => { changeDate("invdate", d); }}  format='MMM dd, yyyy' />
                    </Grid>
                    <Grid item xs={3}>
                        <TimePicker value={formState.invstarttime} onChange={(d: Date | null) => { changeDate("invstart", d); }}  />
                    </Grid>
                    <Grid item xs={3}>
                        <TimePicker value={formState.invendtime} onChange={(d: Date | null) => { changeDate("invend", d); }}  />
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12}><Typography>Location Information</Typography></Grid>
                        <Grid item xs={12}>
                            <TextField multiline rows="2" fullWidth value={formState.invloc} variant='outlined' label='Location' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("invloc", e.target.value); }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField multiline rows="2" fullWidth value={formState.invaddressstreet} variant='outlined' label='Address' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("invaddress", e.target.value); }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth value={formState.invaddresscity} variant='outlined' label='City' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("invcity", e.target.value); }} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField fullWidth value={formState.invaddressstate} variant='outlined' label='State' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("invstate", e.target.value); }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={8}>
                            <Typography>RSVP Details</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>RSVP Due By</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField fullWidth value={formState.invrsvp} variant='outlined' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("invrsvp", e.target.value); }} />
                        </Grid>
                        <Grid item xs={4}>
                            <KeyboardDatePicker fullWidth inputVariant="outlined" value={formState.invresponsedate} onChange={(d: Date | null) => { changeDate("invresponsedate", d); }} format='MMM dd, yyyy' />
                        </Grid>
                    </Grid>
                </Grid>
                : null}
            <Grid item xs={12} container alignItems="center">
            <Grid item xs={2} container justifyContent='flex-end' alignContent='center'><Typography>Addressed To: </Typography></Grid>
            <Grid item xs={4}>
                <Select fullWidth value={formState.sent} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("sentto", e.target.value); }} variant="outlined">
                    <MenuItem value="">Select...</MenuItem>
                    <MenuItem value="HFA/RGA">HFA/RGA</MenuItem>
                    <MenuItem value="HFA">HFA</MenuItem>
                    <MenuItem value="RGA">RGA</MenuItem>
                    <MenuItem value="Family/3">Family/3</MenuItem>
                    <MenuItem value="DFGA">DFGA</MenuItem>
                </Select>
            </Grid>
            {formState.type != "B" && formState.type != "C" ?
                <React.Fragment>
                    <Grid item xs={2} container justifyContent='flex-end' alignContent='center'><Typography>Routed To: </Typography></Grid>
                    <Grid item xs={4}>
                        <Select fullWidth value={formState.routed} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setValue("routed", e.target.value); }} variant="outlined">
                            <MenuItem value="">Select...</MenuItem>
                            <MenuItem value="HFA/RGA">HFA/RGA</MenuItem>
                            <MenuItem value="HFA">HFA</MenuItem>
                            <MenuItem value="RGA">RGA</MenuItem>
                            <MenuItem value="Family/3">Family/3</MenuItem>
                            <MenuItem value="DFGA">DFGA</MenuItem>
                        </Select>
                    </Grid>
                    </React.Fragment> : null}
            </Grid>
            <Grid item xs={12}>
                <ButtonGroup>
                    <Button onClick={() => { props.save(formState); }}>Save</Button>
                    <Button onClick={() => { props.cancel(); }}>Cancel</Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orange: {
            backgroundColor: '#ff6700',
            color: 'white',
            width: '200px'
        },
        red: {
            backgroundColor: 'red',
            color: 'white',
            width: '200px'
        },
        redorange: {
            background: "linear-gradient(90deg, #ff0000 0%, #ff6700 67%)",
            width: '200px',
            color: 'white'
        },
        purple: {
            backgroundColor: 'purple',
            color: 'white',
            width: '200px'
        },
        pink: {
            backgroundColor: 'pink',
            color: 'black',
            width: '200px'
        },
        yellow: {
            backgroundColor: 'yellow',
            color: 'black',
            width: '200px'
        },
        green: {
            backgroundColor: 'green',
            color: 'white',
            width: '200px'
        }
    }));