import { Button, Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { IEntityTag, ITag } from '../../application/models';

interface ITagChipProps {
    tag: ITag | IEntityTag;
    onclick?: undefined | null | ((tag: ITag) => void);
    ondelete?: undefined | null | ((tag: ITag) => void);
    className?: string | undefined;
}

export const TagChip: React.FC<ITagChipProps> = (props, context) => {
    const clickfunc: () => void = () => {
        if (props.onclick != null) {
            props.onclick(props.tag);
        }
    }

    const deletefunc: () => void = () => {
        if (props.ondelete != null) {
            props.ondelete(props.tag);
        }
    }


    return (
        <Tooltip title={props.tag.name} {...props}>
            {props.onclick != null ?
                <Chip label={props.tag.code} style={{ background: props.tag.color, color: props.tag.darkfont ? 'black' : 'white' }} onClick={clickfunc} className={ props.className } /> :
                props.ondelete != null ?
                    <Chip label={props.tag.code} style={{ background: props.tag.color, color: props.tag.darkfont ? 'black' : 'white' }} onDelete={deletefunc} className={props.className} />
                    : <Chip label={props.tag.code} style={{ background: props.tag.color, color: props.tag.darkfont ? 'black' : 'white' }} className={props.className} />}

        </Tooltip>
    );
}