import React, { useContext, useState, useEffect } from 'react';
import { ISubscription } from '../../application/models';
//import { RestApiRepository } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
//import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, GetDataPath, GridReadyEvent, GridApi, ColumnApi, SelectionChangedEvent, RowNode, ValueFormatterParams, IFilterComp, ValueGetterParams, ICellRendererParams  } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import '../../styles/gridstyles.scss';

import { Grid, List, ListItem, Box, Typography, Button, makeStyles, createStyles, Theme, Tooltip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ButtonGroup } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { MakeMonthYearString, MakeShortMonthYearString } from '../../application/helperfuncs';
import { ReceivedCellRenderer, NextExpectedRenderer, PeriodicalActionsRenderer, FieldCellRenderer, IActionsCellRendererParams, BoolCellRenderer } from './cellrenderers';

export interface IPeriodicalListProps {
    items: Array<ISubscription>;
    isLoading: boolean;
    refresh: () => void;
    openReceive: (data: ISubscription) => void;
    openEdit: (data: ISubscription) => void;
    openDeactivate: (data: ISubscription) => void;
    closeDialogs: () => void;
    filter: string;
}

export interface IPeriodicalListState {
    colapi: ColumnApi | null;
    api: GridApi | null;
}


const RenewDateCellRenderer: (params: ICellRendererParams) => string = (params) => {
    let val: ISubscription = params.data;
    if (val != null && val.renew != null) {
        let d: Date = new Date(val.renew);
        return MakeShortMonthYearString(d, true);
    }
    return "";

}

const checkFilter: (node: RowNode, value: string) => boolean = (node: RowNode, value: string) => {
    let data: ISubscription = node.data;
    let chkflt: string = value.toLowerCase();
    return (data.name.toLowerCase().indexOf(chkflt) > -1)
        || (data.account.toLowerCase().indexOf(chkflt) > -1);
}

let filter = "";

export const PeriodicalList: React.FC<IPeriodicalListProps> = (props, context) => {
    const classes = useStyles();
    const [state, setState] = useState<IPeriodicalListState>({ colapi: null, api: null });
    //const api: RestApiRepository = useContext(ApiContext);
   // const { } = useFieldsteadApi();

    const onGridReady: (params: GridReadyEvent) => void = (params) => {
        setState({ ...state, colapi: params.columnApi, api: params.api });
    }

    const isFilterMatched: (n: RowNode) => boolean = (n: RowNode) => {
        return checkFilter(n, filter);
    }

    const isExternalFilterPresent: () => boolean = () => {
        return filter != "";
    }

    const cellStyles = {
        nameStyle: {
            justifyContent: "left"
        }
    }

    const closeDialogs: () => void = () => {
        props.closeDialogs();
    }
    
    const ReceiveIssue: (data: ISubscription) => void = (data) => {
        props.openReceive(data);
    }

    const EditSubscription: (data: ISubscription) => void = (data) => {
        props.openEdit(data);
    }

    const DeactivateSubscription: (data: ISubscription) => void = (data) => {
        props.openDeactivate(data);
    }

    const emphasisCriteria: Array<string> = ["HFA 3222", "RGA FCO"];

    useEffect(() => {
        filter = props.filter;
        if (state.api != null) {
            state.api.onFilterChanged();
        }
    }, [props.filter]);

    const columnDefinitions: Array<ColDef | ColGroupDef> = [
        { width: 100, suppressAutoSize: true, cellRenderer: "ActionRenderer", cellRendererParams: (params: ICellRendererParams) => { return { ...params, receiveItem: ReceiveIssue, editItem: EditSubscription, deactivateItem: DeactivateSubscription }; } },
        //{ cellRenderer: "LogRowActionCellRenderer", headerName: "", width: 100, suppressAutoSize: true },
        { field: 'name', headerName: "Periodical", filter: "agTextColumnFilter", sortable: true, width: 400, suppressAutoSize: true, cellRenderer: "FieldRenderer", cellRendererParams: (params: ICellRendererParams) => { return { ...params, emphasisauto: emphasisCriteria }; } },
        { field: 'type', headerName: "Type", filter: "agSetColumnFilter", sortable: true, width: 90, suppressAutoSize: true, cellRenderer: "FieldRenderer", cellRendererParams: (params: ICellRendererParams) => { return { ...params, emphasisauto: emphasisCriteria }; }  },
        { field: 'auto', headerName: "For", filter: "agSetColumnFilter", sortable: true, width: 100, suppressAutoSize: true, cellRenderer: "FieldRenderer", cellRendererParams: (params: ICellRendererParams) => { return { ...params, emphasisauto: emphasisCriteria }; }  },
        //{ field: 'from', headerName: "From", filter: "agTextColumnFilter", width: 125, sortable: true, autoHeight: true, cellStyle: { 'white-space': 'normal', 'text-align': 'left' } },
        { field: 'renew', headerName: "Renewal Date", width: 130, sortable: true, suppressAutoSize: true, cellRenderer: "RenewDateCellRenderer" },
        { field: 'autorenew', headerName: "Auto-Renew", width: 130, sortable: false, suppressAutoSize: true, cellRenderer: "BoolRenderer", cellRendererParams: (params: ICellRendererParams) => { return { ...params, emphasisauto: emphasisCriteria }; } }
        //{ field: 'received', headerName: "Received Date", width: 150, sortable: true, suppressAutoSize: true, cellRenderer: "ReceivedCellRenderer" }
    ];

    const componentRenderers = {
        RenewDateCellRenderer: RenewDateCellRenderer,
        ReceivedCellRenderer: ReceivedCellRenderer,
        NextExpectedRenderer: NextExpectedRenderer,
        ActionRenderer: PeriodicalActionsRenderer,
        FieldRenderer: FieldCellRenderer,
        BoolRenderer: BoolCellRenderer
    }

    return (
        <div className={`${classes.contentWindow} ag-theme-balham`}>
            <AgGridReact
                rowData={props.items}
                columnDefs={columnDefinitions}
                frameworkComponents={componentRenderers}
                rowSelection="multiple"
                onGridReady={onGridReady}
                rowBuffer={100}
                domLayout='autoHeight'
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={isFilterMatched}
            />
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '100%'
        },
        titleLine: {
            flexGrow: 0,
            flexShrink: 0,
            height: 'auto'
        },
        contentWindow: {
            width: "100%"
        }
    }));