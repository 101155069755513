import { ITag } from '../../../application/models';

export type TagSearchAction = { type: "Search", payload: string }
    | { type: "Refresh" }
    | { type: "Clear" }
    | { type: "Loaded", payload: Array<ITag> };

interface ITagSearch {
    searchValue: string | null;
    loading: boolean;
    searchOptions: Array<ITag>;
    searchMatches: Array<ITag>;
}

function filterTags(search: string | null, options: Array<ITag>): Array<ITag> {
    return options.filter(tag => search == null
        || search == ""
        || (tag.code.indexOf(search) > -1));
}

export function initializeSearch(): ITagSearch {
    return {
        searchValue: null,
        loading: false,
        searchOptions: [],
        searchMatches: []
    };
}

export function tagSearchReducer(state: ITagSearch, action: TagSearchAction): ITagSearch {    
    switch (action.type) {
        case "Search":
            return {
                searchValue: action.payload,
                loading: false,
                searchOptions: state.searchOptions,
                searchMatches: filterTags(action.payload, state.searchOptions)
            };
        case "Clear":
            return {...state, searchValue: "", searchMatches: state.searchOptions}
        case "Refresh":
            return {...state, loading: true, searchOptions: [], searchMatches: [] }
        case "Loaded":
            return {...state, loading: false, searchOptions: action.payload, searchMatches: filterTags(state.searchValue, action.payload)};
        default:
            return state;
    }
}