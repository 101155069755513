import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, Grid, List, ListItem, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useContext, useState, useReducer } from 'react';
//import { RestApiRepository } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';

interface IDeactivateCorrespondentFormState {
    options: Array<string>;
    find: string;
    matches: Array<string>;
}

interface IDeactivateCorrespondentProps {
    closeForm: () => void;
}

type DeactivateAction = { type: "Load", payload: Array<string> } | { type: "Find", payload: string };

function FormStateReducer(state: IDeactivateCorrespondentFormState, action: DeactivateAction) {
    let outstate: IDeactivateCorrespondentFormState = { ...state };
    if (action.type == "Load") {
        outstate.options = action.payload;
    } else if (action.type == "Find") {
        outstate.find = action.payload;
    }

    if (outstate.find == null || outstate.find == "") {
        outstate.matches = [];
    }
    else if (outstate.find.length < 3) {
        outstate.matches = state.options.filter(o => o.startsWith(outstate.find));
    }
    else {
        outstate.matches = outstate.options.filter(o => o.indexOf(outstate.find) >= 0);
    }

    return outstate;
}

export const DeactivateCorrespondents: React.FC<IDeactivateCorrespondentProps> = (props, context) => {
    //let api: RestApiRepository = useContext(ApiContext);
    const { RetireCorrespondentName, FetchContactNames } = useFieldsteadApi();
    const [formState, dispatchFormState] = useReducer(FormStateReducer, { options: [], find: "", matches: [] });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const deactivateCorrespondent: (c: string) => void = (c) => {
        setIsLoading(true);
        RetireCorrespondentName(c).then((data) => {
            dispatchFormState({ type: "Load", payload: data });
            setIsLoading(false);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        FetchContactNames().then((data) => {
            dispatchFormState({ type: "Load", payload: data });
            setIsLoading(false);
        });
    }, []);


    return (
        <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
                <Typography>Use the search filter to find the name or names you wish to remove from the "From" search options and click the trash can button.</Typography>
            </Grid>
            <Grid item xs={10}>
                <TextField variant='outlined' fullWidth size='small' value={formState.find} onChange={(e) => { dispatchFormState({ type: "Find", payload: e.target.value }); } } />                
            </Grid>
            <Grid item xs={2}>
                <Button onClick={ props.closeForm } color='primary' variant='contained'>Close</Button>
            </Grid>
            {isLoading == false ?
                <Grid item xs={12} alignItems='stretch'>
                    <List>
                        {formState.matches.map((v, i) => {
                            return (
                                <ListItem>
                                    <Grid container xs={12}>
                                        <Grid item xs={1}>
                                            <Button onClick={() => { deactivateCorrespondent(v); }} color='primary' variant='contained' size="small">
                                                <FontAwesomeIcon icon={faTrash} size='xs' />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={11}><Typography>{v}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            );
                        })}
                    </List>
                </Grid> : <CircularProgress /> }
        </Grid>
        );
}
