import React, { useState, useContext, useEffect } from 'react';


//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
//import { RestApiRepository } from '../../application/restapirepository';
import { AuthenticationInfo } from '../../contexts/authcontext';

import { IServiceUser } from '../../application/models';

import { Grid, Button, Tooltip, Box, Typography } from '@material-ui/core';

import { UserList } from './list';
import { ResetPasswordForm, PasswordResult } from './reset';
import { EditUserForm, EditResult } from './edit';
import { RemoveUserForm, RemoveResult } from './remove';

interface IUserAdminProps {

}

interface IUserAdminState {
    users: Array<IServiceUser>;
    isLoading: boolean;
    isSaving: boolean;
}

interface IUserPasswordState {
    isopen: boolean;
    passwordUser: IServiceUser | null;
}

interface IEditUserState {
    isopen: boolean;
    editUser: IServiceUser | null;
}

interface IRemoveUserState {
    isopen: boolean;
    removeUser: IServiceUser | null;
}

function GetDefaultUserAdminState(): IUserAdminState {
    return {
        users: [],
        isLoading: true,
        isSaving: false
    };
}

export const UserAdmin: React.FC<IUserAdminProps> = (props, context) => {
    //const api: RestApiRepository = useContext(ApiContext);
    const { FetchUserList } = useFieldsteadApi();
    const [state, setState] = useState<IUserAdminState>(GetDefaultUserAdminState());
    const [pwdstate, setPwdstate] = useState<IUserPasswordState>({ isopen: false, passwordUser: null });
    const [userstate, setUserstate] = useState<IEditUserState>({ isopen: false, editUser: null });
    const [removestate, setRemovestate] = useState<IRemoveUserState>({ isopen: false, removeUser: null });

    useEffect(() => {
        if (state.isLoading) {
            FetchUserList().then((data) => { loaded(data); });
        }
    }, [state.isLoading]);

    const loaded: (data: Array<IServiceUser>) => void = (data) => {
        setState({ ...state, users: data, isLoading: false });
    }

    const resetPwd: (user: IServiceUser) => void = (user) => {
        setPwdstate({ ...pwdstate, isopen: true, passwordUser: user });
    }

    const savePwd: (result: PasswordResult) => void = (result) => {
        setPwdstate({ ...pwdstate, isopen: false, passwordUser: null });
        setState({ ...state, isLoading: true, isSaving: false });
    }

    const cancelPwd: () => void = () => {
        setPwdstate({ ...pwdstate, isopen: false, passwordUser: null });
    }

    const editUser: (user: IServiceUser | null) => void = (user) => {
        setUserstate({ ...userstate, isopen: true, editUser: user });
    }

    const saveUser: (result: EditResult) => void = (result) => {
        setUserstate({ ...userstate, isopen: false, editUser: null });
        setState({ ...state, isLoading: true, isSaving: false });
    }

    const cancelUser: () => void = () => {
        setUserstate({ ...userstate, isopen: false, editUser: null });
    }

    const removeUser: (user: IServiceUser) => void = (user) => {
        setRemovestate({ ...removestate, isopen: true, removeUser: user });
    }

    const cancelRemove: () => void = () => {
        setRemovestate({ ...removestate, isopen: false, removeUser: null });
    }

    const completeRemove: (result: RemoveResult) => void = (result) => {
        setRemovestate({ ...removestate, isopen: false, removeUser: null });
        setState({ ...state, isLoading: true, isSaving: false });
    }

    return (
        <Grid container>
            <Grid item xs={10}>
                <UserList resetPassword={resetPwd} editUser={editUser} entries={state.users} isLoading={state.isLoading} isSaving={state.isSaving} removeUser={removeUser} /> 
            </Grid>
            <Grid item xs={2}>
                <ResetPasswordForm open={pwdstate.isopen} save={savePwd} cancel={cancelPwd} user={pwdstate.passwordUser} />
                <EditUserForm open={userstate.isopen} save={saveUser} cancel={cancelUser} user={userstate.editUser} />
                <RemoveUserForm open={removestate.isopen} save={completeRemove} cancel={cancelRemove} user={removestate.removeUser} />
                <Button onClick={() => { editUser(null); }} variant="contained" color="primary"><Typography>Add User</Typography></Button>
            </Grid>
        </Grid>
    );
}