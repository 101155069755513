export interface IContactSummary {
    id: string;
    flags: Array<string>;
    entitytype: string;
    entityname: string;
    companyName: string;
    contactName: string;
    phonenumbers: Array<string>;
    children: string;
    emails: Array<string>;
    associateNames: Array<string>;
    addresses: Array<any>;
    hasimage: boolean;
}

export interface IContactDetails {
    id: string;
    displaytype: string;
    jobtitle: string;
    department: string;
    lastname: string;
    firstname: string;
    companyName: string;
    contactName: string;
    entityname: string;
    children: string;
    salutation: string;
    flags: Array<string>;
    publicnotes: string;
    internalnotes: string;
    emails: Array<IEmail>;
    phonenumbers: Array<IPhone>;
    relationships: Array<IRelationship>;
    urls: Array<IUrl>;
    addresses: Array<IAddress>;
    lastupdate: string;
    hasimage: boolean;
    image: string | null;
}

export function GetDefaultContactDetails(): IContactDetails {
    return {
        id: "00",
        displaytype: "person",
        jobtitle: "",
        department: "",
        lastname: "",
        firstname: "",
        companyName: "",
        contactName: "",
        entityname: "",
        children: "",
        salutation: "",
        flags: [], 
        publicnotes: "", 
        internalnotes: "",
        emails: [],
        phonenumbers: [],
        relationships: [],
        urls: [],
        addresses: [],
        lastupdate: "",
        hasimage: false,
        image: null
    }
}

export interface IEmail {
    id: string;
    label: string;
    type: string;
    email: string;
}

export interface IPhone {
    id: string;
    label: string;
    type: string;
    phonenumber: string;
}

export interface IRelationship {
    id: string;
    label: string;
    type: string;
    name: string;
}

export interface IUrl {
    id: string;
    label: string;
    type: string;
    url: string;
}

export interface IAddress {
    id: string;
    label: string;
    type: string;
    streetAddress: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
}

export interface IMailLog {
    id: number | null;
    type: "G" | "I" | "C" | "B" | "T" | null;
    category: string | null;
    receiveddate: Date | null;
    routed: string | null;
    sent: string | null;
    comment: string | null;
    invdate: Date | null;
    invloc: string | null;
    invrsvp: string | null;
    invaddressstreet: string | null;
    invaddresscity: string | null;
    invaddressstate: string | null;
    invaddresszip: string | null;
    invstarttime: Date | null;
    invendtime: Date | null;
    invresponsedate: Date | null;
    user: string | null;
    folder: string | null;
    from: string | null;
    timesensitive: boolean | null;
    to215: boolean | null;
}

export function GetDefaultMailLog(): IMailLog {
    return {
        id: null,
        type: null,
        category: null,
        receiveddate: new Date(),
        routed: null,
        sent: null,
        comment: null,
        invdate: null,
        invloc: null,
        invrsvp: null,
        invaddressstreet: null,
        invaddresscity: null,
        invaddressstate: null,
        invaddresszip: null,
        invstarttime: null,
        invendtime: null,
        invresponsedate: null,
        user: null,
        folder: null,
        from: null,
        timesensitive: null,
        to215: null
    }
}

export interface IServiceUser {
    id: number;
    username: string;
    maillog: boolean;
    directory: boolean;
    users: boolean;
    superuser: boolean;
}

export enum DocRequestType {
    Gifts = "gifts",
    RGA = "rga",
    HFA = "hfa",
    Invites = "invites",
    Email = "email",
    Contacts = "contacts",
    vCard = "vcard",
    ContactDetail = "contactdetail",
    ContactsClassic = "contactsclassic",
    Salutations = "salutations"
}

export interface IDocumentRequest {
    ids: Array<string> | null;
    fromDate: string;
    toDate: string;
    mode: DocRequestType;
    routefilter: "HFA" | "RGA" | "HFA/RGA" | "DFGA" | "HFA/RGA/DFGA" | null;
    flags: Array<string> | null;
    search: string | null;
}

export interface ISubscription {
    id: number;
    name: string;
    renew: Date | null;
    cycle: string;
    account: string;
    auto: string;
    phone: string;
    type: "Print" | "Print & Digital" | "Digital" | "Email" | null | undefined;
    notes: string | null;
    received: Array<string>;
    autorenew: boolean;
    autorenewnote: string | null;
}

export function GetDefaultSubscription(): ISubscription {
    return {
        id: 0,
        name: "",
        renew: null,
        cycle: "",
        account: "",
        auto: "",
        phone: "",
        type: "Print",
        notes: "",
        received: [],
        autorenew: false,
        autorenewnote: null
    };
}

export interface IEntityTag {
    entitytagid: string,
    id: string,
    name: string,
    code: string,
    color: string,
    active: boolean,
    priority: number,
    darkfont: boolean
}

export function GetDefaultEntityTag(): IEntityTag {
    return {
        entitytagid: "",
        id: "",
        name: "",
        code: "",
        color: "",
        active: true,
        priority: 0,
        darkfont: true
    }
}

export interface ITag {
    id: string,
    name: string,
    code: string,
    color: string,
    active: boolean,
    priority: number,
    darkfont: boolean
}