import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { Authenticator } from '@aws-amplify/ui-react';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { LicenseManager } from 'ag-grid-enterprise';
import config from './application/appconfig.json';

LicenseManager.setLicenseKey("CompanyName=Three Hill Path Incorporated,LicensedGroup=3HP_AG_Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=3,AssetReference=AG-017785,ExpiryDate=11_September_2022_[v2]_MTY2Mjg1MDgwMDAwMA==cb3850f0fee9b6a708dedf1ee2eadf24");
ReactDOM.render(
    <React.StrictMode>
        <Authenticator.Provider>
            <BrowserRouter>            
                <App apiendpointurl={ config.apiconfig.apiendpointurl } />
            </BrowserRouter>
        </Authenticator.Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
