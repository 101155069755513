import React, { useEffect, useContext, useState, useReducer, Reducer, Fragment } from 'react';
import { Grid, TextField, Button, Typography, ButtonGroup } from '@material-ui/core';
import { IAddress } from '../../application/models';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

interface IContactAddressProps {
    currentaddresses: Array<IAddress>,
    updateAddresses: (Addresses: Array<IAddress>) => void
}

interface IContactAddressState {
    newlabel: string;
    newstreetaddress: string;
    newcity: string;
    newstate: string;
    newpostalcode: string;
    newcountry: string;
    newtype: string;
    addresses: Array<IAddress>;
}

function GetEmptyContactAddressState(): IContactAddressState {
    return {
        newlabel: "",
        newstreetaddress: "",
        newcity: "",
        newstate: "",
        newpostalcode: "",
        newcountry: "",
        newtype: "",
        addresses: []
    };
}

interface IViewAddressProps {
    address: IAddress;
    deleteAddress: (addr: IAddress) => void;
    changeAddress: (oldaddr: IAddress, newaddr: IAddress) => void;
}

interface IViewAddressState {
    editMode: boolean;
}

interface IEditAddressProps {
    addressVars: IAddress;
    commitEdit: (newAddr: IAddress) => void;
    cancelEdit: () => void;
}

export const EditAddress: React.FC<IEditAddressProps> = (props, context) => {
    let [addrState, setAddrState] = useState<IAddress>({ ...props.addressVars });
    return (
        <Grid container xs={12}>
            <Grid item container xs={12} sm={10}>
                <Grid item xs={12} md={12}>
                    <TextField fullWidth value={addrState.label} variant='outlined' label='Label' size='small' onChange={(e) => { setAddrState({ ...addrState, label: e.target.value }); }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField multiline rows="2" fullWidth value={addrState.streetAddress} variant='outlined' label='Address' size='small' onChange={(e) => { setAddrState({ ...addrState, streetAddress: e.target.value }); }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth value={addrState.city} variant='outlined' label='City' size='small' onChange={(e) => { setAddrState({ ...addrState, city: e.target.value }); }} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField fullWidth value={addrState.state} variant='outlined' label='State' size='small' onChange={(e) => { setAddrState({ ...addrState, state: e.target.value }); }} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField fullWidth value={addrState.zipcode} variant='outlined' label='Pstl Code' size='small' onChange={(e) => { setAddrState({ ...addrState, zipcode: e.target.value }); }} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField fullWidth value={addrState.country} variant='outlined' label='Country' size='small' onChange={(e) => { setAddrState({ ...addrState, country: e.target.value }); }} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
                <ButtonGroup fullWidth><Button variant='outlined' onClick={() => { props.commitEdit(addrState); }}><SaveIcon /></Button><Button variant='outlined' onClick={props.cancelEdit}><CancelIcon /></Button></ButtonGroup>
            </Grid>
        </Grid>
    );
}

export const ViewAddress: React.FC<IViewAddressProps> = (props, context) => {
    let e: IAddress = props.address;
    let [addressState, setAddressState] = useState<IViewAddressState>({ editMode: false });

    const cancelEdit: () => void = () => {
        setAddressState({ ...addressState, editMode: false });
    }

    if (addressState.editMode == false) {
        return (
            <Grid container>
                <Grid item container xs={12} md={10}>
                    <Grid item xs={12} md={12}><Typography align="left">{e.label}</Typography></Grid>
                    <Grid item xs={12}>{e.streetAddress.split('\n').filter((i) => { return i != null && i != ""; }).map((v, i) => { return (<Typography align="left" key={'addrsplit' + i.toString()}>{v}</Typography>) })}</Grid>
                    <Grid item xs={12}><Typography align="left">{e.city}, {e.state}&nbsp;&nbsp;{e.zipcode}&nbsp;&nbsp;{e.country}</Typography></Grid>
                </Grid>
                <Grid item xs={12} md={2}><ButtonGroup fullWidth><Button onClick={() => { setAddressState({ ...addressState, editMode: true }); }}><EditIcon /></Button><Button><DeleteIcon onClick={() => { props.deleteAddress(e); }} /></Button></ButtonGroup></Grid>
            </Grid>
        );
    }
    else {
        return (
            <EditAddress addressVars={e} commitEdit={(n) => { props.changeAddress(e, n); setAddressState({...addressState, editMode: false}); }} cancelEdit={cancelEdit} />
            );
    }
}

export const AddressEditor: React.FC<IContactAddressProps> = (props, context) => {
    let [state, setState] = useState<IContactAddressState>({ ...GetEmptyContactAddressState(), addresses: props.currentaddresses });
    
    useEffect(() => {
        setState({ ...state, addresses: props.currentaddresses });
    }, [props.currentaddresses]);

    const addAddress: () => void = () => {
        let newItem: IAddress = {
            id: "",
            type: "other",
            label: state.newlabel,
            streetAddress: state.newstreetaddress,
            city: state.newcity,
            state: state.newstate,
            zipcode: state.newpostalcode,
            country: state.newcountry
        }
        props.updateAddresses([...state.addresses, newItem]);
        setState({...state, newlabel: "", newtype: "", newstreetaddress: "", newcity: "", newstate: "", newpostalcode: "", newcountry: ""});
    }

    const deleteAddress: (a: IAddress) => void = (a) => {
        let ix: number = state.addresses.indexOf(a);
        if (ix != -1) {
            let addresses: Array<IAddress> = state.addresses.filter((ad) => { return ad != a; });
            props.updateAddresses(addresses);
        //    setState({ ...state, addresses: addresses });
        }
    }

    const changeAddress: (oldAddr: IAddress, newAddr: IAddress) => void = (o, n) => {
        let addrs = [...state.addresses];
        let ix: number = addrs.indexOf(o);
        addrs[ix] = n;
        props.updateAddresses(addrs);
        //setState({...state, addresses: addrs});
    }

    const changeLabel: (event: any) => void = (e) => {
        setState({ ...state, newlabel: e.target.value });
    }

    const changeStreetAddress: (event: any) => void = (e) => {
        setState({ ...state, newstreetaddress: e.target.value });
    }
    
    const changeCity: (event: any) => void = (e) => {
        setState({ ...state, newcity: e.target.value });
    }

    const changeState: (event: any) => void = (e) => {
        setState({ ...state, newstate: e.target.value });
    }

    const changePostalCode: (event: any) => void = (e) => {
        setState({ ...state, newpostalcode: e.target.value });
    }

    const changeCountry: (event: any) => void = (e) => {
        setState({ ...state, newcountry: e.target.value });
    }

    return (
        <Fragment>
            <Grid container alignItems='flex-start' spacing={1}>
                <Grid item container xs={12} sm={10}>
                    <Grid item xs={12} md={12}>
                        <TextField fullWidth value={state.newlabel} variant='outlined' label='Label' size='small' onChange={changeLabel} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField multiline rows="2" fullWidth value={state.newstreetaddress} variant='outlined' label='Address' size='small' onChange={changeStreetAddress} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth value={state.newcity} variant='outlined' label='City' size='small' onChange={changeCity} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField fullWidth value={state.newstate} variant='outlined' label='State' size='small' onChange={changeState} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField fullWidth value={state.newpostalcode} variant='outlined' label='Pstl Code' size='small' onChange={changePostalCode} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField fullWidth value={state.newcountry} variant='outlined' label='Country' size='small' onChange={changeCountry} />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                    <ButtonGroup fullWidth><Button variant='outlined' onClick={addAddress}><AddIcon /></Button></ButtonGroup>
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1}>
                {state.addresses.map((e, i) => {
                    return (
                        <ViewAddress address={e} deleteAddress={deleteAddress} changeAddress={changeAddress} key={'addrlst' + i.toString()} />
                    );
                })}
            </Grid>
        </Fragment>
    );
}