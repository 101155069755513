import { IContactSummary, IContactDetails, IMailLog, IServiceUser, IDocumentRequest, ISubscription, IEntityTag, ITag } from './models';

import flagdata from './flags.json';

export interface RestApiConfig {
    endpointurl: string;
    authtoken: string;
}

export interface ApiResponse {
    success: boolean;
    message: string;
}

export interface FlagData {
    name: string;
    flag: string;
}

export function ShortDateString(date: Date): string {
    return date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString() + "-" + date.getDate().toString();
}



export interface ISearchCriteria {
    fullname: boolean;
    children: boolean;
    company: boolean;
    relationships: boolean;
    email: boolean;
    location: boolean;
    notes: boolean;
}
/*
export class RestApiRepository {
    baseurl: string;
    authtoken: string;

    constructor(cfg: RestApiConfig) {
        this.baseurl = cfg.endpointurl;
        this.authtoken = cfg.authtoken;

    }

    FetchDirectoryEntries(letter: string, flag: string, text: string, searchcriteria: ISearchCriteria, callback: (data: Array<IContactSummary>) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Contact";
        let searchflags: number = 0;
        let modifier: string = "";

        searchflags = (searchcriteria.fullname ? 1 : 0)
            + (searchcriteria.children ? 2 : 0)
            + (searchcriteria.company ? 4 : 0)
            + (searchcriteria.relationships ? 8 : 0)
            + (searchcriteria.email ? 16 : 0)
            + (searchcriteria.location ? 32 : 0)
            + (searchcriteria.notes ? 64 : 0);

        if (letter != "") {
            modifier = "letter=" + letter;
        }
        if (flag != "") {
            if (modifier.length > 0) modifier = modifier + "&";
            modifier = modifier + "tag=" + flag;
        }
        if (text != "") {
            if (modifier.length > 0) modifier = modifier + "&";
            modifier = modifier + "search=" + text.replace("<", "");
        }
        if (searchflags > 0) {
            if (modifier.length > 0) modifier = modifier + "&";
            modifier = modifier + "flds=" + searchflags.toString();
        }
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "GET"
        };
        if (modifier.length > 0) modifier = "?" + modifier;

        fetch(url + modifier, req)
            .then(response => {
                if(response.ok) return response.json();
            })
            .then(results => {
                callback(results);
            }).catch(error => {
                alert(error);
            });
    }

    FetchContact(contactid: string, callback: (data: IContactDetails) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + '/Contact/' + contactid;
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "GET"
        };
        fetch(url, req)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                callback(results);
            }).catch(error => {
                alert(error);
            });
    }

    SaveContact(contact: IContactDetails, callback: (responsestring: string) => void): void {
        // Implemented in Hook
        let url: string = this.baseurl + '/Contact/';
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "POST",
            body: JSON.stringify(contact)
        };

        fetch(url, req).then(result => {
            if (result.ok) { return result.text(); }
            else { callback("Failure"); }
        }).then(id => {
            if (id != null) {
                callback(id);
            }
            else {
                callback("Failure");
            }
        }).catch(error => {
                callback("Error");
            });
    }

    DeleteContact(contactid: string | null, callback: () => void): void {
        // Implemented in hook
        if (contactid != null) {
            let url: string = this.baseurl + '/Contact/' + contactid;
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "DELETE"
            };

            fetch(url, req).then(result => {
                if (result.ok) callback();
            }).catch(error => {
                alert("Delete failed.");
            });
        }
    }

    DeleteMailItem(mailitem: number | null, callback: () => void): void {
        // Implemented in hook
        if (mailitem != null) {
            let url: string = this.baseurl + '/MailLog/' + mailitem;
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "DELETE"
            };

            fetch(url, req).then(result => {
                if (result.ok) callback();
            }).catch(error => {
            });
        }
    }

    HydrateMailLog(data: any): IMailLog {
        // Implemented in hook
        return {
            id: data.id,
            type: data.type,
            category: data.category,
            receiveddate: data.receiveddate != null ? new Date(data.receiveddate) : null,
            routed: data.routed,
            sent: data.sent,
            comment: data.comment,
            invdate: data.invdate != null ? new Date(data.invdate) : null,
            invloc: data.invloc,
            invrsvp: data.invrsvp,
            invaddressstreet: data.invaddressstreet,
            invaddresscity: data.invaddresscity,
            invaddressstate: data.invaddressstate,
            invaddresszip: data.invaddresszip,
            invstarttime: data.invstarttime != null ? new Date(data.invstarttime) : null,
            invendtime: data.invendtime != null ? new Date(data.invendtime) : null,
            invresponsedate: data.invresponsedate != null ? new Date(data.invresponsedate) : null,
            user: data.user,
            folder: data.folder,
            from: data.from,
            timesensitive: data.timesensitive,
            to215: data.to215
        }
    }

    FetchMailLog(startdate: Date, enddate: Date, callback: (data: Array<IMailLog>) => void): void {
        // Implemented in Hook
        let url: string = this.baseurl + "/MailLog/";
        let startstring: string = startdate.getFullYear().toString() + "-" + (startdate.getMonth() + 1).toString() + "-" + startdate.getDate().toString();
        let endstring: string = enddate.getFullYear().toString() + "-" + (enddate.getMonth() + 1).toString() + "-" + enddate.getDate().toString();
        let fullurl: string = url + "?startdate=" + encodeURIComponent(startstring) + "&enddate=" + encodeURIComponent(endstring);
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "GET"
        };

        fetch(fullurl, req).then(response => {
            if (response.ok) return response.json();
            else throw new Error("Unable to process");
        }).then(results => {
            callback(results.map((v: any, i: number) => { return this.HydrateMailLog(v);}));
        }).catch (error => {
            alert("API Error");
        });
    }

    FetchMailLogEntry(id: number, callback: (data: IMailLog) => void): void {
        // IMplemented in hook
        let url: string = this.baseurl + "/MailLog/" + id.toString();
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "GET"
        };

        fetch(url, req).then(response => {
            if (response.ok) return response.json();
            else throw new Error("Unable to process");
        }).then(result => {
            callback(this.HydrateMailLog(result));
        }).catch(error => {
            alert("API Error");
        });
    }

    SaveMailLogEntry(maillog: IMailLog, callback: (responsestring: string) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/MailLog/";

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "POST",
            body: JSON.stringify(maillog)
        };

        fetch(url, req).then(result => {
            if (result.ok) { return result.text(); }
            else { callback("Failure"); }
        }).then(id => {
            if (id != null) {
                callback(id);
            }
            else {
                callback("Failure");
            }
        }).catch(error => {
            callback("Error");
        });
    }

    FetchMailCategories(): Promise<Array<string>>{
        // Implemented in hook
        let url: string = this.baseurl + "/MailCategories/";
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "GET"
        };

        return fetch(url, req).then(response => {
            if (response.ok) return response.json();
            else throw new Error("Category Fetch Failed");
        }).catch(error => {
            alert(error.message);
        });
    }
       
    FetchContactNames(): Promise<Array<string>> {
        // Implemented in hook
        let url: string = this.baseurl + "/Senders";
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "GET"
        };

        return fetch(url, req).then(response => {
            if (response.ok) return response.json();
            else throw new Error("Contacts Fetch Failed");
        }).catch(error => { });
    }

    RetireCorrespondentName(name: string): Promise<Array<string>> {
        // Implemented in hook
        let url: string = this.baseurl + "/Senders";
        let bodydata = { value: name };
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "DELETE",
            body: JSON.stringify(bodydata)
        };

        return fetch(url, req).then(response => {
            if (response.ok) return response.json();
            else throw new Error("Correspondent Deletion Failed");
        }).catch(error => { });
    }

    FetchFormData(callback: (data: IFormData) => void): void {
        // Implemented in hook
        this.FetchMailCategories().then(cats => {
            this.FetchContactNames().then(names => {
                callback({ categories: cats, contacts: names });
            });
        });
    }

    HydrateServiceUser(item: any): IServiceUser {
        return {
            id: item.id,
            directory: item.directory,
            maillog: item.maillog,
            superuser: item.superuser,
            username: item.username,
            users: item.users
        };
    }
    
    fetchingUsers: boolean = false;
    FetchUserList(callback: (data: Array<IServiceUser>) => void): void {
        // Implemented in Hook
        if (this.fetchingUsers == false) {
            this.fetchingUsers = true;
            let url: string = this.baseurl + "/Admin/User";
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "GET"
            };
            let foo: Headers | null = null;
            fetch(url, req).then(response => {
                if (response.ok) return response.json();
                else throw new Error("Unable to process");
            }).then(results => {
                callback(results.map((v: any, i: number) => { return this.HydrateServiceUser(v); }));
            }).catch(error => {
                alert("API Error");
            }).finally(() => {
                this.fetchingUsers = false;
            });
        }
    }

    SaveUser(user: IServiceUser, callback: (result: { success: boolean, item: IServiceUser | null }) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Admin/User";

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "POST",
            body: JSON.stringify(user)
        };

        fetch(url, req).then(result => {
            if (result.ok) { return result.json(); }
            else { callback({ success: false, item: null }); }
        }).then(item => {
            if (item != null) {
                callback({ success: true, item: item });
            }
            else {
                callback({ success: false, item: null });
            }
        }).catch(error => {
            callback({ success: false, item: null });
        });
    }

    RemoveUser(user: IServiceUser, callback: (result: { success: boolean, message?: string }) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Admin/User";

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "DELETE",
            body: JSON.stringify(user)
        };

        fetch(url, req).then(result => {
            if (result.ok) { return "Success"; }
            else { callback({ success: false, message: "Failure" }); }
        }).then(item => {
            if (item != null) {
                callback({ success: true, message: "Success" });
            }
            else {
                callback({ success: false, message: "Failure" });
            }
        }).catch(error => {
            callback({ success: false, message: "Failure" });
        });
    }

    ResetPassword(user: IServiceUser, newpassword: string, callback: (result: { success: boolean, message: string }) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Admin/User/Reset";

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "PUT",
            body: JSON.stringify({ data: user, update: newpassword })
        };

        fetch(url, req).then(result => {
            if (result.ok) { return result.text(); }
            else { callback({ success: false, message: "Failure" }); }
        }).then(id => {
            if (id != null) {
                callback({success: true, message: id});
            }
            else {
                callback({ success: false, message: "Failure" });
            }
        }).catch(error => {
            callback({ success: false, message: error.message });
        });
    }

    RequestDocument(request: IDocumentRequest, callback: (result: { success: boolean, filename: string, file: Blob | null }) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Documents";

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "POST",
            body: JSON.stringify(request)
        };

        fetch(url, req).then(result => {
            if (result.ok) { return result.blob(); }
            else { callback({ success: false, filename: "N/A", file: null }); }
        }).then(blob => {
            if (blob != null) {
                callback({ success: true, filename: request.fromDate + "_" + request.toDate + "_" + request.mode + ".html", file: blob });
            }
            else {
                callback({ success: false, filename: "N/A", file: null });
            }
        }).catch(error => {
            callback({ success: false, filename: "N/A", file: null });
        });
    }

    RequestVCard(request: IDocumentRequest, callback: (result: { success: boolean, filename: string, file: Blob | null }) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Documents";

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "POST",
            body: JSON.stringify(request)
        };

        fetch(url, req).then(result => {
            if (result.ok) { return result.blob(); }
            else { callback({ success: false, filename: "N/A", file: null }); }
        }).then(blob => {
            let id: string = request.ids != null && request.ids.length > 0 ? request.ids[0] : "req";
            if (blob != null) {
                callback({ success: true, filename: id + "_" + request.mode + ".vcf", file: blob });
            }
            else {
                callback({ success: false, filename: "N/A", file: null });
            }
        }).catch(error => {
            callback({ success: false, filename: "N/A", file: null });
        });
    }

    FetchPeriodicals(callback: (results: Array<ISubscription>) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Periodical";
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "GET"
        };

        fetch(url, req).then(response => {
            if (response.ok) return response.json();
            else throw new Error("Unable to process");
        }).then(results => {
            callback(results.map((v: any, i: number) => { return this.HydrateSubscription(v); }).sort((a: ISubscription, b: ISubscription) => { return ('' + a.name).localeCompare(b.name); }));
        }).catch(error => {
            alert("API Error");
        }).finally(() => {
            this.fetchingUsers = false;
        });
    }

    HydrateSubscription(item: any): ISubscription {
        return {
            id: item.id,
            name: item.name,
            renew: item.renew,
            cycle: item.cycle,
            account: item.account,
            auto: item.auto,
            phone: item.phone,
            type: item.type,
            notes: item.notes ?? "",
            received: item.received,
            autorenew: item.autorenew,
            autorenewnote: item.autorenewnote
        };
    }

    ReceiveIssue(id: number, date: string, callback: (response: ApiResponse) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Periodical/" + id.toString() + "/Issue";
        let request: any = {
            datestring: date
        };
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "POST",
            body: JSON.stringify(request)
        };

        fetch(url, req).then(result => {
            if (result.ok) { callback({ success: true, message: "Issue posted" }); }
            else { callback({ success: false, message: "Issue failed to post" }); }
        }).catch(error => {
            callback({ success: false, message: "Error posting Issue: " + error.message });
        });
    }

    SaveSubscription(data: ISubscription, callback: (response: ApiResponse) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Periodical/";
        let request: ISubscription = { ...data,  received: [] };
        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "POST",
            body: JSON.stringify(request)
        };

        fetch(url, req).then(result => {
            if (result.ok) { callback({ success: true, message: "Subscription saved" }); }
            else { callback({ success: false, message: "Subscription failed to post" }); }
        }).catch(error => {
            callback({ success: false, message: "Error posting Subscription: " + error.message });
        });
    }

    DeleteSubscription(data: ISubscription, callback: (response: ApiResponse) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Periodical/" + data.id;

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "DELETE"
        };

        fetch(url, req).then(result => {
            if (result.ok) { return "Success"; }
            else { callback({ success: false, message: "Failure" }); }
        }).then(item => {
            if (item != null) {
                callback({ success: true, message: "Success" });
            }
            else {
                callback({ success: false, message: "Failure" });
            }
        }).catch(error => {
            callback({ success: false, message: "Failure" });
        });
    }

    DeleteIssue(data: ISubscription, issuedate: string, callback: (response: ApiResponse) => void): void {
        // Implemented in hook
        let url: string = this.baseurl + "/Periodical/" + data.id + "/Issue/" + issuedate;

        let req: RequestInit = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authtoken
            },
            method: "DELETE"
        };

        fetch(url, req).then(result => {
            if (result.ok) { return "Success"; }
            else { callback({ success: false, message: "Failure" }); }
        }).then(item => {
            if (item != null) {
                callback({ success: true, message: "Success" });
            }
            else {
                callback({ success: false, message: "Failure" });
            }
        }).catch(error => {
            callback({ success: false, message: "Failure" });
        });
    }

    GetContactImageUrl(id: string): string {
        // Implemented in hook
        let url: string = this.baseurl + "/Contact/" + id + "/Image";

        return url;
    }

    GetStates(): Array<FlagData> {
        // Implemented in hook
        return flagdata.locations.states;
    }

    GetInternational(): Array<FlagData> {
        // Implemented in hook
        return flagdata.locations.abroad;
    }

    GetPersonal(): Array<FlagData> {
        // Implemented in hook
        return flagdata.personal;
    }

    GetOtherOptions(): Array<FlagData> {
        // Implemented in hook
        return flagdata.other;
    }

    HydrateEntityTag(item: any): IEntityTag {
        return {
            entitytagid: item.entitytagid,
            id: item.id,
            active: item.active,
            code: item.code,
            color: item.color,
            name: item.name,
            priority: item.priority,
            darkfont: item.darkfont
        };
    }

    HydrateTag(item: any): ITag {
        return {
            id: item.id,
            active: item.active,
            code: item.code,
            color: item.color,
            name: item.name,
            priority: item.priority,
            darkfont: item.darkfont
        };
    }

    FetchEntityTags(entitytype: string, entityid: string | number): Promise<Array<IEntityTag>> {
        //Implemented in hook
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let url: string = this.baseurl + "/EntityTags/" + entitytype + "/" + entityid.toString();
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "GET"
            };

            fetch(url, req).then(response => {
                if (response.ok) return response.json();
                else throw new Error("Unable to process");
            }).then(results => {
                resolve(results.map((v: any, i: number) => { return this.HydrateEntityTag(v); }));
            }).catch(error => {
                reject("API Error");
            });
        });
    }

    FetchTags(): Promise<Array<ITag>> {
        //Implemented in hook
        return new Promise<Array<ITag>>((resolve, reject) => {
            let url: string = this.baseurl + "/Tags";
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "GET"
            };

            fetch(url, req).then(response => {
                if (response.ok) return response.json();
                else throw new Error("Unable to process");
            }).then(results => {
                resolve(results.map((v: any, i: number) => { return this.HydrateTag(v); }));
            }).catch(error => {
                reject("API Error on FetchTags");
            });
        });
    }

    SaveTag(tag: ITag): Promise<ITag> {
        // Implemented in hook
        return new Promise<ITag>((resolve, reject) => {
            let url: string = this.baseurl + "/Tag";
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "POST",
                body: JSON.stringify(tag)
            };

            fetch(url, req).then(response => {
                if (response.ok) return response.json();
                else throw new Error("Unable to process");
            }).then(results => {
                resolve(this.HydrateTag(results));
            }).catch(error => {
                reject("API Error on SaveTag");
            });
        });
    }


    AddEntityTags(tags: Array<ITag>, entityid: string, entitytype: string): Promise<Array<IEntityTag>> {
        // Implemented in hook
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let url: string = this.baseurl + "/EntityTags/" + entitytype + "/" + entityid;
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "POST",
                body: JSON.stringify(tags)
            };

            fetch(url, req).then(response => {
                if (response.ok) return response.json();
                else throw new Error("Unable to process");
            }).then(results => {
                resolve(results.map((et: any, i: number) => { return this.HydrateEntityTag(et); }));
            }).catch(error => {
                reject("API Error on AddEntityTag");
            });
        });
    }

    AddEntityTag(tag: ITag, entityid: string, entitytype: string): Promise<Array<IEntityTag>> {
        // Implemented in hook
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let taglist: Array<ITag> = []
            taglist.push(tag);
            let url: string = this.baseurl + "/EntityTags/" + entitytype + "/" + entityid;
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "POST",
                body: JSON.stringify(taglist)
            };

            fetch(url, req).then(response => {
                if (response.ok) return response.json();
                else throw new Error("Unable to process");
            }).then(results => {
                resolve(results.map((et: any, i: number) => { return this.HydrateEntityTag(et); }));
            }).catch(error => {
                reject("API Error on AddEntityTag");
            });
        });
    }

    RemoveEntityTag(entitytag: IEntityTag, entityid: string, entitytype: string): Promise<Array<IEntityTag>> {
        // Implemented in hook
        return new Promise<Array<IEntityTag>>((resolve, reject) => {
            let url: string = this.baseurl + "/EntityTags/" + entitytype + "/" + entityid + "/" + entitytag.entitytagid;
            let req: RequestInit = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.authtoken
                },
                method: "DELETE"
            };

            fetch(url, req).then(response => {
                if (response.ok) return response.json();
                else throw new Error("Unable to process");
            }).then(results => {
                resolve(results.map((et: any, i: number) => { return this.HydrateEntityTag(et); }));
            }).catch(error => {
                reject("API Error on AddEntityTag");
            });
        });
    }    
}
*/
export interface IFormData {
    contacts: Array<string>;
    categories: Array<string>;
}