import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, Typography, FormControlLabel, Checkbox, DialogContentText, DialogContent, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, DialogActions, Button, Grid, Select, MenuItem, TextField, makeStyles, createStyles, Theme, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
//import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ISubscription, GetDefaultSubscription } from '../../application/models';
import { MakeDateString } from '../../application/helperfuncs';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DangerButton, SafeButton } from '../controls/buttons';

export interface IEditSubscriptionProps {
    subscription: ISubscription | null;
    open: boolean;
    save: (data: ISubscription) => void;
    cancel: () => void;
}

export const EditSubscriptionDialog: React.FC<IEditSubscriptionProps> = (props, context) => {
    const [formdata, setFormdata] = useState<ISubscription>(GetDefaultSubscription());
    const [renewDate, setRenewDate] = useState<Date | null>(null);

    const saveData: () => void = () => {
        let savedate: Date = renewDate != null ? renewDate : new Date(1, 1, 1);
        let savesub: ISubscription = {
            ...formdata,
            renew: savedate
        }
        props.save(savesub);
    }

    useEffect(() => {
        if (props.subscription == null) {
            setFormdata(GetDefaultSubscription());
            setRenewDate(null);
        }
        else {
            setFormdata({ ...props.subscription });
            setRenewDate(props.subscription.renew);
        }
    }, [props.subscription]);

    return (
        <Dialog open={props.open}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DialogTitle><Typography>Subscription Information</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {formdata.id == 0 ? "Add " : "Modify "} subscription information below.
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item container xs={12} justifyContent='center' alignItems='center'>
                            <Grid item xs={12} md={3}>
                                <Typography>Periodical Title</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <TextField fullWidth value={formdata.name} variant='outlined' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormdata({ ...formdata, name: e.target.value }); }} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} justifyContent='center' alignItems='center'>
                            <Grid item xs={12} md={3}>
                                <Typography>Account #</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <TextField fullWidth value={formdata.account} variant='outlined' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormdata({ ...formdata, account: e.target.value }); }} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} justify='center' alignItems='center'>
                            <Grid item xs={12} md={3}>
                                <Typography>Subscription Type</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Select fullWidth value={formdata.type} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormdata({ ...formdata, type: e.target.value }); }} variant="outlined">
                                    <MenuItem value="" disabled>Select...</MenuItem>
                                    <MenuItem value="Print">Print</MenuItem>
                                    <MenuItem value="Print & Digital">Print & Digital</MenuItem>
                                    <MenuItem value="Digital">Digital</MenuItem>
                                    <MenuItem value="Email">Email</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} justifyContent='center' alignItems='center'>
                            <Grid item xs={12} md={3}><Typography>For</Typography></Grid>
                            <Grid item xs={12} md={9}>
                                <Autocomplete
                                    size="small"
                                    value={formdata.auto}
                                    inputValue={formdata.auto}
                                    onInputChange={(event: object, newInputValue: string) => {
                                        setFormdata({ ...formdata, auto: newInputValue });
                                    }}
                                    id="autoInput"
                                    freeSolo
                                    options={["HFA 3222", "RGA FCO", "HFA", "RGA", "HFA Digital", "RGA Digital", "Office", "Steve Ferguson", "Joe Gorra", "Ann Hirou", "Erin Whitlow"]}
                                    fullWidth
                                    renderInput={(params: any) => (
                                        <TextField {...params} margin="normal" variant="outlined" size="small" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} justifyContent='center' alignItems='center'>
                            <Grid item xs={12} md={3}>
                                <Typography>Delivery Cycle</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Select fullWidth value={formdata.cycle} onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormdata({ ...formdata, cycle: e.target.value }); }} variant="outlined">
                                    <MenuItem value="" disabled>Select...</MenuItem>
                                    <MenuItem value="Bi-monthly">Bi-monthly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Bi-weekly">Bi-weekly</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} justify='center' alignItems='center'>
                            <Grid item xs={12} md={3}>
                                <Typography>Phone #</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <TextField fullWidth value={formdata.phone} variant='outlined' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormdata({ ...formdata, phone: e.target.value }); }} />
                            </Grid>
                        </Grid>
                        {formdata.type != "Digital" ?
                            <Grid item container xs={12} justify='center' alignItems='flex-start'>
                                <Grid item xs={12} md={3}>
                                    <Typography>Received Dates</Typography>
                                </Grid>
                                <Grid container item xs={12} md={9}>
                                    <Grid item xs={12}>
                                        {(props.subscription != null && (props.subscription.received.length ?? 0) > 0) ?
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary>
                                                    <Typography>{props.subscription.received[0].substring(5, 7) + "-" + props.subscription.received[0].substring(8, 10) + "-" + props.subscription.received[0].substring(0, 4)}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container xs={12}>
                                                        {props.subscription.received.map((r, i) => {
                                                            if (i > 0) {
                                                                return (
                                                                    <Grid item xs={12}>
                                                                        <Typography>{r.substring(5, 7) + "-" + r.substring(8, 10) + "-" + r.substring(0, 4)}</Typography>
                                                                    </Grid>);
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })}
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel> : null}
                                    </Grid>
                                </Grid>
                            </Grid> : null}
                        <Grid item container xs={12} justifyContent='center' alignItems='center'>
                            <Grid item xs={12} md={3}>
                                <Typography>Renew Date</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <KeyboardDatePicker value={renewDate} onChange={(d: Date | null) => { setRenewDate(d); }} fullWidth inputVariant="outlined" format='MM/yyyy' />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} justify='center' alignItems='center'>
                            <Grid item xs={12} md={3}>
                                <Typography></Typography>
                            </Grid>
                            <Grid item xs={8} md={6}>
                                <FormControlLabel
                                    control={<Checkbox checked={formdata.autorenew} onChange={() => { setFormdata({ ...formdata, autorenew: !formdata.autorenew }); }} name="users" />}
                                    label="On Auto-Renewal"
                                />
                            </Grid>
                            <Grid item xs={4} md={3}>
                                {formdata.autorenew ?
                                    <TextField fullWidth value={formdata.autorenewnote} variant='outlined' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormdata({ ...formdata, autorenewnote: e.target.value }); }} />
                                    : null}
                            </Grid>
                        </Grid>


                        <Grid item container xs={12}>
                            <Grid item xs={12} md={3} alignContent='stretch' >
                                <Typography>Notes</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <TextField fullWidth multiline value={formdata.notes} variant='outlined' size='small' onChange={(e: React.ChangeEvent<{ name?: string | undefined, value: any }>) => { setFormdata({ ...formdata, notes: e.target.value }); }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SafeButton onClick={() => { saveData(); }} variant="contained"><FontAwesomeIcon icon={faSave} /></SafeButton>
                    <DangerButton onClick={() => { props.cancel(); }} variant="contained"><FontAwesomeIcon icon={faWindowClose} /></DangerButton>
                </DialogActions>
            </MuiPickersUtilsProvider>
        </Dialog>
    );
}
