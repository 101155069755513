import React, { useEffect, useContext, useState, useReducer, Reducer, Fragment } from 'react';
import { Grid, TextField, Button, Typography, ButtonGroup } from '@material-ui/core';
import { IRelationship } from '../../application/models';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

interface IContactRelationshipProps {
    currentrelationships: Array<IRelationship>,
    updateRelationships: (Relationships: Array<IRelationship>) => void
}

interface IContactRelationshipState {
    newlabel: string;
    newrelationship: string;
    newtype: string;
    relationships: Array<IRelationship>;
}


interface IViewRelationshipProps {
    relationship: IRelationship;
    deleteRelationship: (relationship: IRelationship) => void;
    changeRelationship: (oldrelationship: IRelationship, newrelationship: IRelationship) => void;
}

interface IViewRelationshipState {
    editMode: boolean;
}

interface IEditRelationshipProps {
    relationshipVars: IRelationship;
    commitEdit: (newRelationship: IRelationship) => void;
    cancelEdit: () => void;
}


export const EditRelationship: React.FC<IEditRelationshipProps> = (props, context) => {
    let [relationshipState, setRelationshipState] = useState<IRelationship>({ ...props.relationshipVars });
    return (
        <Grid container xs={12}>
            <Grid item container xs={12} sm={10}>
                <Grid item xs={12} md={3}>
                    <TextField fullWidth value={relationshipState.label} variant='outlined' label='Label' size='small' onChange={(e) => { setRelationshipState({ ...relationshipState, label: e.target.value }); }} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <TextField fullWidth value={relationshipState.name} variant='outlined' label='Relationship' size='small' onChange={(e) => { setRelationshipState({ ...relationshipState, name: e.target.value }); }} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
                <ButtonGroup fullWidth><Button variant='outlined' onClick={() => { props.commitEdit(relationshipState); }}><SaveIcon /></Button><Button variant='outlined' onClick={props.cancelEdit}><CancelIcon /></Button></ButtonGroup>
            </Grid>
        </Grid>
    );
}

export const ViewRelationship: React.FC<IViewRelationshipProps> = (props, context) => {
    let e: IRelationship = props.relationship;
    let [relationshipState, setRelationshipState] = useState<IViewRelationshipState>({ editMode: false });

    const cancelEdit: () => void = () => {
        setRelationshipState({ ...relationshipState, editMode: false });
    }

    if (relationshipState.editMode == false) {
        return (
            <Grid container>
                <Grid item xs={12} md={3}><Typography align="left">{e.label}</Typography></Grid>
                <Grid item xs={12} md={7}><Typography align="left">{e.name}</Typography></Grid>
                <Grid item xs={12} md={2}><ButtonGroup fullWidth><Button onClick={() => { setRelationshipState({ ...relationshipState, editMode: true }); }}><EditIcon /></Button><Button><DeleteIcon onClick={() => { props.deleteRelationship(e); }} /></Button></ButtonGroup></Grid>
            </Grid>
        );
    }
    else {
        return <EditRelationship relationshipVars={e} commitEdit={(n) => { props.changeRelationship(e, n); setRelationshipState({ ...relationshipState, editMode: false }); }} cancelEdit={cancelEdit} />
    }
}


export const RelationshipEditor: React.FC<IContactRelationshipProps> = (props, context) => {
    let [state, setState] = useState<IContactRelationshipState>({ newlabel: "", newrelationship: "", newtype: "", relationships: props.currentrelationships });
    
    useEffect(() => {
        setState({ ...state, relationships: props.currentrelationships });
    }, [props.currentrelationships]);

    const addUrl: () => void = () => {
        let newItem: IRelationship = {
            id: "",
            type: "other",
            label: state.newlabel,
            name: state.newrelationship
        }
        props.updateRelationships([...state.relationships, newItem]);
        setState({ ...state, newlabel: "", newrelationship: "", newtype: "" });
    }

    const deleteRelationship: (r: IRelationship) => void = (r) => {
        let ix: number = state.relationships.indexOf(r);
        if (ix != -1) {
            let relationships: Array<IRelationship> = state.relationships.filter((re) => { return re != r; });
            props.updateRelationships(relationships);
            //setState({ ...state, relationships: relationships });
        }
    }

    const updateRelationship: (oldRelationship: IRelationship, newRelationship: IRelationship) => void = (o, n) => {
        let relationships = [...state.relationships];
        let ix: number = relationships.indexOf(o);
        relationships[ix] = n;
        props.updateRelationships(relationships);
        //setState({ ...state, relationships: relationships });
    }

    const changeLabel: (event: any) => void = (e) => {
        setState({ ...state, newlabel: e.target.value });
    }

    const changeRelationship: (event: any) => void = (e) => {
        setState({ ...state, newrelationship: e.target.value });
    }
    
    return (
        <Fragment>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} md={3}>
                    <TextField fullWidth value={state.newlabel} variant='outlined' label='Label' size='small' onChange={changeLabel} />
                </Grid>
                <Grid item xs={12} md={7}>
                    <TextField fullWidth value={state.newrelationship} variant='outlined' label='Name' size='small' onChange={changeRelationship} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <ButtonGroup fullWidth><Button variant='outlined' onClick={addUrl}><AddIcon /></Button></ButtonGroup>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1}>
                {state.relationships.map((e, i) => {
                    return (
                        <ViewRelationship relationship={e} changeRelationship={updateRelationship} deleteRelationship={deleteRelationship} />
                    );
                })}
            </Grid>
        </Fragment>
    );
}