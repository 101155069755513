import React, { useState } from 'react';
import { IFieldsteadApiConfig } from '../application/fieldsteadapiconfig';

const FieldsteadAPIConfigContext = React.createContext<[IFieldsteadApiConfig, (v: IFieldsteadApiConfig) => void]>([{ endpointurl: "", getaccesstoken: null }, (v) => { }]);

interface IFieldsteadAPIProviderProps {
    endpointurl: string;
    getaccesstoken: () => Promise<string>;
}

const FieldsteadAPIConfigProvider: React.FC<IFieldsteadAPIProviderProps> = (props) => { 
    const [config, setConfig] = useState<IFieldsteadApiConfig>({ endpointurl: props.endpointurl, getaccesstoken: props.getaccesstoken });

    return (
        <FieldsteadAPIConfigContext.Provider value={[config, setConfig]}>
            { props.children }
        </FieldsteadAPIConfigContext.Provider>
        );
}

export { FieldsteadAPIConfigContext, FieldsteadAPIConfigProvider };