import React, { useContext, useState, useEffect } from 'react';
import { IDocumentRequest, DocRequestType } from '../../application/models';

//import { ApiContext } from '../../contexts/apicontext';
//import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
import { ShortDateString } from '../../application/restapirepository';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
//import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, DialogContentText, TextField, Grid, Button, FormControlLabel, FormGroup, Checkbox, Select, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons';


export interface IDocumentRequestProps {
    cancel: () => void;
    request: (req: IDocumentRequest) => void;
    open: boolean;
    baseReportStart: Date | null;
    baseReportEnd: Date | null;
}

interface IDocRequestState {
    fromDate: Date | null;
    toDate: Date | null;
    mode: DocRequestType | "";
    routefilter: "HFA" | "RGA" | "HFA/RGA" | "DFGA" | "HFA/RGA/DFGA" | null;
}

const GetDefaultState: () => IDocRequestState = () => {
    return {
        fromDate: null,
        toDate: null,
        mode: "",
        routefilter: null
    };
}

const formRequest: (state: IDocRequestState) => IDocumentRequest = (s) => {
    if (s.mode != "") {
        let docReq: IDocumentRequest = {
            ids: null,
            fromDate: s.fromDate != null ? ShortDateString(s.fromDate) : "",
            toDate: s.toDate != null ? ShortDateString(s.toDate) : "",
            mode: s.mode,
            routefilter: s.routefilter,
            flags: null,
            search: null
        };

        return docReq;
    }
    throw "Invalid Form Data";
}

export const DocumentDialog: React.FC<IDocumentRequestProps> = (props, context) => {
    const [state, setState] = useState<IDocRequestState>({ ...GetDefaultState(), fromDate: props.baseReportStart, toDate: props.baseReportEnd });

    const handleMode: (e: React.ChangeEvent<{ name?: string, value: any }>) => void = (e) => {
        let setMode: DocRequestType | "" = e.target.value;

        setState({ ...state, mode: setMode });
    };

    useEffect(() => {
        if (props.open) {
            setState({ ...GetDefaultState(), fromDate: props.baseReportStart, toDate: props.baseReportEnd });
        }
    }, [props.open]);

    return (
        <Dialog open={props.open}>
            <DialogTitle><Typography>Request Document/Report</Typography></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select dates and criteria for document to be generated.  When you submit your request, a new tab/window will open with your PDF.
                </DialogContentText>
                <Grid item xs={12}>
                    <KeyboardDatePicker fullWidth inputVariant="outlined" value={state.fromDate} maxDate={state.toDate} label="Start" onChange={(e) => { setState({ ...state, fromDate: e }); }} format='MMM dd, yyyy' />
                </Grid>
                <Grid item xs={12}>
                    <KeyboardDatePicker fullWidth inputVariant="outlined" value={state.toDate} minDate={state.fromDate} label="End" onChange={(e) => { setState({ ...state, toDate: e }); }} format='MMM dd, yyyy' />
                </Grid>
                <Grid item xs={12}>
                    <Select value={state.mode} onChange={handleMode} fullWidth label="Document" variant="outlined">
                        <MenuItem disabled value="">Select Document/Report...</MenuItem>
                        <MenuItem value="rga">RGA</MenuItem>
                        <MenuItem value="hfa">HFA</MenuItem>
                        <MenuItem value="invites">Invitations</MenuItem>
                        <MenuItem value="gifts">Gifts</MenuItem>
                    </Select>
                </Grid>
                {/*}
                <Grid item xs={12}>
                    <Select value={state.routefilter}>
                        <MenuItem value="">Any</MenuItem>
                        <MenuItem value="HFA">HFA</MenuItem>
                        <MenuItem value="RGA">RGA</MenuItem>
                        <MenuItem value="HFA/RGA">HFA/RGA</MenuItem>
                        <MenuItem value="DFGA">DFGA</MenuItem>
                        <MenuItem value="HFA/RGA/DFGA">HFA/RGA/DFGA</MenuItem>
                    </Select>
                </Grid>
                */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.request(formRequest(state)); }} color="primary" variant="contained"><FontAwesomeIcon icon={faCheck} /></Button>
                <Button onClick={() => { props.cancel(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faWindowClose} /></Button>
            </DialogActions>
        </Dialog>
    );
}