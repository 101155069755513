import React, { useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, Typography, DialogContentText, DialogContent, DialogActions, Button, Grid, Select, MenuItem, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { DropzoneArea } from 'material-ui-dropzone';

//import { RestApiRepository } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
import { IContactDetails, GetDefaultContactDetails } from '../../application/models';

interface IUploadPictureProps {
    open: boolean;
    contact: IContactDetails | null;
    cancel: () => void;
    save: (file: string) => void;
}

interface IUploadPictureFormState {
    file: File | null;
}

const toBase64: (file: File) => Promise<string | ArrayBuffer | null> = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const UploadPictureDialog: React.FC<IUploadPictureProps> = (props, context) => {
    const [formdata, setFormdata] = useState<IUploadPictureFormState>({ file: null});

    const saveData: () => void = () => {
        if (formdata.file != null) {
            toBase64(formdata.file).then((b64data: any) => {
                if (b64data != null) {
                    props.save(b64data.replace("data:image/jpeg;base64,", ""));
                }
            });
        }
    }

    const setFile: (files: Array<File>) => void = (files) => {
        setFormdata({ ...formdata, file: files[0] });
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle><Typography>Subscription Information</Typography></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Images must be less than 1mb and must be JPG or JPEG files.
                </DialogContentText>
                <Grid container spacing={1}>
                    <Grid item container xs={12} justifyContent='center' alignItems='center'>
                        <DropzoneArea onChange={setFile} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { saveData(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faSave} /></Button>
                <Button onClick={() => { props.cancel(); }} color="primary" variant="contained"><FontAwesomeIcon icon={faWindowClose} /></Button>
            </DialogActions>
        </Dialog>
    );
}
