import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, CircularProgress, Grid, List, ListItem, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { ITag } from '../../application/models';
//import { RestApiRepository } from '../../application/restapirepository';
//import { ApiContext } from '../../contexts/apicontext';
import { useFieldsteadApi } from '../../hooks/useFieldsteadApi';
import { EditTag } from './edit';
import { ViewTag } from './view';

interface ITagManagerProps {

}

interface ITagManagerState {
    tags: Array<ITag>;
    isloading: boolean;
    editTag: ITag | null;
}



export const TagManager: React.FC<ITagManagerProps> = (props, context) => {
    const [state, setState] = useState<ITagManagerState>({ tags: [], isloading: true, editTag: null });
    const { FetchTags, SaveTag } = useFieldsteadApi();
    //const api: RestApiRepository = useContext(ApiContext);

    const fetchTags: () => void = () => {
        FetchTags().then((data) => {
            setState({ ...state, tags: data, isloading: false });
        });
    }

    const saveChange: (tag: ITag) => void = (tag) => {
        setState({ ...state, isloading: true, editTag: null });
        SaveTag(tag).then(() => {
            FetchTags().then((data) => {
                setState({ ...state, editTag: null, tags: data, isloading: false });
            });
        });
    }

    useEffect(() => {
        fetchTags();
    }, []);

    return (
        <Grid container>
            {state.isloading == false ?
                <List style={{ width: "100%" }}>
                    <ListItem key='tagli-legend'>
                        {(state.editTag == null || state.editTag.id != '00000000-0000-0000-0000-000000000000') ?
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography>Tag</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Name</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>Priority</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>Active?</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button color='primary' onClick={() => { setState({ ...state, editTag: { id: "00000000-0000-0000-0000-000000000000", code: "", name: "", color: "#eeeeee", active: true, darkfont: true, priority: 0 } }); }}><FontAwesomeIcon icon={faPlus} /></Button>
                                </Grid>
                            </Grid> :
                            <EditTag
                                tag={state.editTag}
                                save={ saveChange }
                                cancel={ () => { setState({ ...state, editTag: null }); } }
                            />
                        }
                    </ListItem>
                    {state.tags.sort((a, b) => { return ('' + a.code).localeCompare(b.code); }).map(t => {
                        if (state.editTag == null || state.editTag.id != t.id) {
                            return (
                                <ListItem key={t.id}>
                                    <ViewTag tag={t} edit={() => { if (state.editTag == null) { setState({ ...state, editTag: t }); } }} />
                                </ListItem>
                            );
                        }
                        else {
                            return (
                                <ListItem key={t.id}>
                                    <EditTag tag={t} save={saveChange} cancel={() => { setState({ ...state, editTag: null }); }} />
                                </ListItem>
                            );
                        }
                    })}
                </List> : <CircularProgress />}
        </Grid>
    );
}